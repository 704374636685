import { BonusService } from '@obr-core/services/api'

export const BONUSES_BONUS_DETAILS = 'BONUSES_BONUS_DETAILS'

const bonusService = BonusService.getInstance()

/**
 * load Bonus details
 * @param {string} id idBonusChunk
 * @returns {Promise<OBR.Bonuses.BonusDetails[]>}
 */
const bonusDetail = (id: number): Promise<OBR.Bonuses.BonusDetails[]> =>
    bonusService.getBonusDetails(id)

export default {
    [BONUSES_BONUS_DETAILS]: bonusDetail,
}
