import { formatInTimeZone, toZonedTime } from 'date-fns-tz'

import { I18nService } from '@obr-core/services/I18nService'
import { DT_ONE_DAY } from '@obr-core/config/date-time'
import { userStoreService } from '@obr-core/services/store'

const i18nService = I18nService.getInstance()

/**
 * Wrapper function for the date-fns format function
 * @param date
 * @param formatStr
 */
export function formatDate(date: number | Date, formatStr: string): string {
    const locale = i18nService.getDateLocale()
    const timezone = userStoreService.timezone()

    return formatInTimeZone(date, timezone, formatStr, {
        locale,
    })
}

/**
 * Format date to the 'YYYY-MM-DD' ISO 8601 format
 * @param date - JavaScript Date,
 */
export function formatDateISO(date: Date): string {
    const dateZoned = toZonedTime(date, userStoreService.timezone())
    const year = dateZoned.getFullYear()
    const month = `0${dateZoned.getMonth() + 1}`.slice(-2)
    const day = `0${dateZoned.getDate()}`.slice(-2)

    return `${year}-${month}-${day}`
}

/**
 * Get number of days from provided day to now
 */
export function getDaysFromNow(date: string | Date): number {
    return Math.ceil(
        (new Date().getTime() - new Date(date).getTime()) / DT_ONE_DAY
    )
}
