import { ROUTER_LOCALISED_ROUTES } from '@obr-core/config/router'
import { getPreferredLocale } from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'

/**
 * Get locale routes
 * @param locale
 */
export function getLocaleRoutes(locale: string): OBR.Common.Object<string> {
    const routesLocale: OBR.Common.Object<string> = {}

    try {
        const routes: OBR.Common.Object<OBR.Common.Object<string>> = JSON.parse(
            ROUTER_LOCALISED_ROUTES
        )

        for (const key in routes) {
            routesLocale[key] = routes[key][locale.substr(0, 2)] || key
        }
    } catch {
        // no-op
    }

    return routesLocale
}

/**
 * Routes locale
 */
export const routesLocale = getLocaleRoutes(
    I18nService.getInstance(getPreferredLocale()).getLanguage()
)
