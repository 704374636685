import { RunnerFormService, RaceService } from '@obr-core/services/api'
import { FormTabsType } from '@obr-ui/components/RunnerForm/config'

/**
 * Events call names
 */
export const RACES_RACE_CARD = 'RACES_RACE_CARD'

export const RACES_RUNNER_FORM = 'RUNNER_FORM'

export const RACES_H2H_PER_RACE = 'RACES_H2H_PER_RACE'
export const RACES_H2H_UPCOMING = 'RACES_H2H_UPCOMING'

const raceService = RaceService.getInstance()
const runnerFormService = RunnerFormService.getInstance()

const raceCard = (args: string): Promise<OBR.Race.Race | null> =>
    raceService.getRaceById(args)

const h2HPerRace = (args: string) => raceService.getRacesH2HByRaceId(args)

const h2HRaceUpcoming = () => raceService.getRacesH2HUpcoming(true)

const runnerForm = async (args: {
    id: string
    field: FormTabsType
}): Promise<OBR.RunnerForm.RunnerForm | null> => {
    const results: OBR.RunnerForm.RunnerForm | null =
        await runnerFormService.getById(args.id, args.field)
    return results
}

export default {
    [RACES_RACE_CARD]: raceCard,
    [RACES_RUNNER_FORM]: runnerForm,
    [RACES_H2H_PER_RACE]: h2HPerRace,
    [RACES_H2H_UPCOMING]: h2HRaceUpcoming,
}
