import axios from 'axios'
import https from 'https'

import {
    HTTP_XSRF_COOKIE,
    HTTP_XSRF_HEADER,
    HTTP_AUTH_RESPONSE_STATUS_CODES,
} from '../config/http' //'@obr-core/config/http'
import { userStoreService } from '@obr-core/services/store'
// import { USER_REQUIRE_AUTH } from '@obr-core/store/modules/user/actions'

export const abortController = new AbortController()

// Create global ajax config
const config: OBR.Http.AjaxRequestConfig = {
    xsrfCookieName: HTTP_XSRF_COOKIE,
    xsrfHeaderName: HTTP_XSRF_HEADER,
    signal: abortController.signal,
}

// The following runs when is executed from the server
if (typeof window === 'undefined') {
    config.httpsAgent = new https.Agent({ rejectUnauthorized: false }) // TODO: Add certificates
    config.baseURL = `https://app-racebets.local.com` //TODO: take brand and url from env
}

// Export only as ajax instance
export const http: OBR.Http.AjaxInstance = axios.create(config)

/**
 * Binds a response interceptor to ajax instance, that will handle 401
 * errors
 *
 * @param store
 */
export function registerResponseInterceptors(
    cb?: (err: OBR.Http.AjaxError) => void
) {
    http.interceptors.request.use(
        (config) => {
            if (config.url?.includes('/rest/')) {
                config.headers['x-rb-language'] = userStoreService.language()
                const token = userStoreService.getToken()
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    http.interceptors.response.use(
        (response: OBR.Http.AjaxResponse) => {
            return response
        },
        (err: OBR.Http.AjaxError) => {
            cb && cb(err)
            // TODO: http://jira.racebetsint.com/browse/PS-1534
            const statusCodes = HTTP_AUTH_RESPONSE_STATUS_CODES
            if (
                !err ||
                !err.response ||
                (err.response.status &&
                    statusCodes.indexOf(+err.response.status) === -1)
            ) {
                return Promise.reject(err)
            }

            if (err.response.status === 401) {
                // Temp disabled to avoid changing aliases to relative paths because this file is imported in node server where aliases do not work for now.
                // store.dispatch(USER_REQUIRE_AUTH)
            }

            return Promise.reject(err)
        }
    )
    return http
}
