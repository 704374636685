import { RouteLocationNormalizedLoaded } from 'vue-router'

import { QUERY_DRAWER } from '@obr-core/config/route'
import { useApp } from '@obr-core/store/app'
import { useUi } from '@obr-core/store/ui'
import { isIframe } from '@obr-core/helpers/app.helpers'
class UiStoreService {
    isTabletPortrait(): boolean {
        return useUi().isTabletPortrait
    }
    isTabletLandscape(): boolean {
        return useUi().isTabletLandscape
    }
    isLaptop(): boolean {
        return useUi().isLaptop
    }
    isDesktop(): boolean {
        return useUi().isDesktop
    }
    isOnlyMobile(): boolean {
        return (
            !this.isTabletPortrait() &&
            !this.isTabletLandscape() &&
            !this.isLaptop() &&
            !this.isDesktop()
        )
    }
    isOnlyTabletPortrait(): boolean {
        return (
            this.isTabletPortrait() &&
            !this.isTabletLandscape() &&
            !this.isLaptop() &&
            !this.isDesktop()
        )
    }
    isOnlyTabletLandscape(): boolean {
        return this.isTabletLandscape() && !this.isLaptop() && !this.isDesktop()
    }
    isOnlyLaptop(): boolean {
        return this.isLaptop() && !this.isDesktop()
    }
    isOnlyDesktop(): boolean {
        return this.isDesktop()
    }
    /**
     * Is Tablet Portrait screen and one of drawers is visible
     */
    isTabletPortraitWithDrawerVisible(): boolean {
        return (
            this.isOnlyTabletPortrait() &&
            (this.isLeftDrawerVisible() || this.isRightDrawerVisible())
        )
    }
    /**
     * Is Tablet landscape screen and one drawers is visible
     */
    isTabletLandscapeWithOneDrawerVisible(): boolean {
        return (
            this.isOnlyTabletLandscape() &&
            (this.isLeftDrawerVisible() || this.isRightDrawerVisible())
        )
    }
    /**
     * Is Tablet landscape screen and both drawers are visible
     */
    isTabletLandscapeWithBothDrawersVisible(): boolean {
        return (
            this.isOnlyTabletLandscape() &&
            this.isLeftDrawerVisible() &&
            this.isRightDrawerVisible()
        )
    }
    headerHeight(): number {
        return useUi().headerHeight
    }
    subHeaderHeight(): number {
        return useUi().subHeaderHeight
    }
    getIframeHeight() {
        return useUi().iframeHeight
    }
    getIframeScroll() {
        return useUi().iframeScroll
    }
    getIframeTop() {
        return useUi().iframeTop
    }
    getLastDrawerOpened(): 'left' | 'main' | 'right' {
        return useUi().lastDrawerOpened
    }
    getScrollMap(key: 'left' | 'main' | 'right'): number {
        return useUi().scrollMap[key]
    }

    isLeftDrawerVisible(): boolean {
        return useApp().left_drawer_visible
    }
    isRightDrawerVisible(): boolean {
        return useApp().right_drawer_visible
    }
    isLeftMenuVisible(): boolean {
        return useApp().left_menu_visible
    }
    isFocusOnStakeInput(): boolean {
        return useApp().is_focus_on_input
    }
    isUiResizing(): boolean {
        return useUi().isUIResizing
    }
    isRouteChanging(): boolean {
        return useUi().isRouteChanging
    }

    /* Mutations */
    setTabletPortrait(status: boolean) {
        useUi().setTabletPortrait(status)
    }
    setTabletLandscape(status: boolean) {
        useUi().setTabletLandscape(status)
    }
    setLaptop(status: boolean) {
        useUi().setLaptop(status)
    }
    setDesktop(status: boolean) {
        useUi().setDesktop(status)
    }
    setHeaderHeight(height: number) {
        useUi().setHeaderHeight(height)
    }
    setSubHeaderHeight(height: number) {
        useUi().setSubHeaderHeight(height)
    }

    setIframeHeight(height: number) {
        useUi().setIframeHeight(height)
    }
    setIframeTop(top: number) {
        useUi().setIframeTop(top)
    }
    setIframeScroll(scroll: number) {
        useUi().setIframeScroll(scroll)
    }

    setLeftMenuVisible(status: boolean) {
        useApp().setLeftMenuVisible(status)
    }
    setLeftDrawerVisible(status: boolean) {
        useApp().setLeftDrawerVisible(status)
    }
    setRightDrawerVisible(status: boolean) {
        useApp().setRightDrawerVisible(status)
    }
    toggleDrawer({
        position,
        value,
        route,
        router,
    }: {
        position: QUERY_DRAWER
        value: boolean
        route: RouteLocationNormalizedLoaded
        router: any
    }) {
        if (this.isOnlyMobile() && !isIframe()) {
            this.setDrawerQueries({ position, value, route, router })
        } else {
            uiStoreService.setDrawerVisible(position, value)
        }
    }
    setDrawerVisible(position: QUERY_DRAWER, status: boolean) {
        this.setAllDrawersHidden()

        if (position === QUERY_DRAWER.MENU) {
            uiStoreService.setLeftMenuVisible(status)
        }
        if (position === QUERY_DRAWER.MEETINGS) {
            uiStoreService.setLeftDrawerVisible(status)
        }
        if (position === QUERY_DRAWER.BETSLIP) {
            uiStoreService.setRightDrawerVisible(status)
        }
    }
    setDrawerQueries({
        position,
        value,
        route,
        router,
    }: {
        position: QUERY_DRAWER
        value: boolean
        route: RouteLocationNormalizedLoaded
        router: any
    }) {
        const query = route.query[position] as string | undefined

        if (query && query === '1') {
            // remove query
            router.push({
                query: { ...route.query, [position]: undefined },
            })
        } else if (!query && value) {
            // add query
            router.push({
                query: {
                    ...route.query,
                    ...(position === QUERY_DRAWER.MEETINGS
                        ? {
                              [QUERY_DRAWER.BETSLIP]: undefined,
                              [position]: 1,
                          }
                        : {
                              [QUERY_DRAWER.MEETINGS]: undefined,
                              [position]: 1,
                          }),
                },
            })
        }
    }
    setAllDrawersHidden() {
        if (!this.isOnlyMobile()) return

        useApp().setLeftDrawerVisible(false)
        useApp().setRightDrawerVisible(false)
        useApp().setLeftMenuVisible(false)
    }
    setFocusOnInput(status: boolean) {
        useApp().setFocusOnInput(status)
    }
    setLastDrawerOpened(drawer: 'left' | 'main' | 'right') {
        useUi().setLastDrawerOpened(drawer)
    }
    setScrollMap(key: 'left' | 'main' | 'right', value: number) {
        useUi().setScrollMap(key, value)
    }
    setIsUIResizing(status: boolean) {
        useUi().setIsUIResizing(status)
    }
    setIsRouteChanging(status: boolean) {
        useUi().setIsRouteChanging(status)
    }
}

export const uiStoreService = new UiStoreService()
