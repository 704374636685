import { defineStore } from 'pinia'

import { SSRService } from '@obr-core/services/SSRService'
import {
    extendRaceByRaceCardSocket,
    extendRaceByEventSocket,
} from '@obr-core/helpers/race.helpers'
import { FormTabsType } from '@obr-ui/components/RunnerForm/config'
import { CacheService } from '@obr-core/services/CacheService'
import {
    RACES_RACE_CARD,
    RACES_RUNNER_FORM,
    RACES_H2H_UPCOMING,
} from '@obr-core/services/CacheService/resolvers/races'
import { FavouritesService, RaceService } from '@obr-core/services/api'
import { BetSpecialType } from '@obr-core/config/betting'

import { initialState } from './state'

const cacheService = CacheService.getInstance()
const raceService = RaceService.getInstance()

export const useRaces = defineStore('obr-store/races', {
    state: initialState,
    actions: {
        onRaceCardLoadRace(raceId: string) {
            const raceSSR = SSRService.getInstance().getRaceCardOnce()
            if (raceSSR) {
                this.onSetRace(this.sortRunners(raceSSR))
                return this.racesCache[raceId]
            }

            // Check if race has runners. If not it is partial object from betslip and we have to display skeleton
            const _race =
                this.racesCache[raceId]?.runners && this.racesCache[raceId]

            const racePromise: Promise<OBR.Race.Race> = cacheService
                .doCall(RACES_RACE_CARD, {
                    args: raceId,
                })
                .then((race: OBR.Race.Race) => {
                    if (race) {
                        this.onSetRace(this.sortRunners(race))
                    }

                    return this.racesCache[raceId]
                })
            return _race ? Promise.resolve(_race) : racePromise
        },

        sortRunners(race: OBR.Race.Race) {
            // sort runners by program number or fixed odds for ante post - in case that there is no final positions for finishet race
            // this will be default order. In case sorting is set on final positions and only some runners have it
            // those who do not have it will be at the end sorted by program number
            const sortingKey = race.is_ante_post ? 'odds_fxw' : 'program_number'
            race.runners.sort(
                (a: OBR.Race.Runner, b: OBR.Race.Runner) =>
                    a[sortingKey] - b[sortingKey]
            )
            return race
        },
        //FORM SECTIONS
        onRaceCardLoadRunnerForm(payload: {
            id: string
            field: FormTabsType
        }): Promise<OBR.RunnerForm.RunnerForm> {
            return cacheService.doCall(RACES_RUNNER_FORM, {
                args: payload,
                cacheable: true,
            })
        },
        //update from RaceCard socket
        onRaceCardUpdateRaceByRaceCardSocket({
            id,
            race,
        }: {
            id: string
            race: OBR.Race.SocketRaceCardResponse
        }) {
            if (this.racesCache[id]) {
                const oldRace = JSON.parse(JSON.stringify(this.racesCache[id]))
                this.onSetRace(extendRaceByRaceCardSocket(oldRace, race))
            }
        },
        onRaceCardUpdateByEventSocket({
            id,
            race,
        }: {
            id: string
            race: OBR.Race.SocketRaceCardResponse
        }) {
            if (this.racesCache[id]) {
                const oldRace = JSON.parse(JSON.stringify(this.racesCache[id]))

                this.onSetRace(extendRaceByEventSocket(oldRace, race))
            }
        },

        onRaceCardUpdateRaceStatus(id: string, status: OBR.Race.RaceStatus) {
            if (this.racesCache[id]) {
                const oldRace = JSON.parse(JSON.stringify(this.racesCache[id]))
                this.onSetRace(Object.assign(oldRace, { status: status }))
            }
        },

        onRaceCardAddRunnerToCache({
            runner,
            raceID,
        }: {
            runner: OBR.Race.Runner
            raceID: string
        }) {
            if (this.racesCache[raceID] && this.racesCache[raceID]?.runners) {
                const runners = this.racesCache[raceID].runners
                if (runners) {
                    for (const key in runners) {
                        if (runners[key].id === runner.id) {
                            runners[key] = runner
                            this.onSetRace(this.racesCache[raceID])
                            break
                        }
                    }
                }
            } else {
                this.onAddRunnerToCache(runner)
            }
        },
        onSetRace(race?: OBR.Race.Race) {
            // TODO: refactor all code that is not typed properly
            // i.e. to handle set undefined race
            if (!race) {
                return
            }
            // this cache is used like apollo races:ID
            this.onSetRacesCache({ race })

            // update cache in isomorphic call
            const id = race.id
            cacheService.doCall(RACES_RACE_CARD, {
                args: id,
                cacheable: true,
                preCache: this.racesCache[id],
            })

            // update cache sibiling for all sibiling races
            race.sibling_races?.forEach((sibiling) => {
                // if race present in cache update sibiling info
                if (this.racesCache[sibiling.id]) {
                    this.racesCache[sibiling.id].sibling_races =
                        race.sibling_races
                    // save for next call if need
                    cacheService.doCall(RACES_RACE_CARD, {
                        args: sibiling.id,
                        cacheable: true,
                        preCache: this.racesCache[sibiling.id],
                    })
                }
            })
        },
        onSetRacesCache({
            races,
            race,
        }: {
            races?: OBR.Race.Race[]
            race?: OBR.Race.Race
        }) {
            if (race) {
                this.racesCache[race.id] = {
                    ...this.racesCache[race.id],
                    ...race,
                }
            } else {
                races?.forEach((_race) => {
                    if (!this.racesCache[_race.id]) {
                        this.racesCache[_race.id] = _race
                    }
                })
            }
            this.racesCache = { ...this.racesCache }
            this.onUpdateRunnerCache(this.racesCache)
        },

        onAddSpecialRaceToCache(specialRace: OBR.Race.Race) {
            this.racesCache[specialRace.id] = { ...specialRace }

            if (specialRace.runners.length > 0) {
                specialRace.runners.forEach((specialRunner) => {
                    this.runnerCache[specialRunner.id] = {
                        ...(this.runnerCache[specialRunner.id] || {}),
                        ...specialRunner,
                    }
                })
            }
        },
        /**
         * update runner cached info
         * @param {OBR.Common.Object<OBR.Race.Race>} races
         */
        onUpdateRunnerCache(races: OBR.Common.Object<OBR.Race.Race>) {
            const newRunnerCached: OBR.Common.Object<OBR.Race.Runner> = {}
            for (const raceIndex in races) {
                if (races[raceIndex].runners) {
                    races[raceIndex].runners?.forEach((runner) => {
                        newRunnerCached[runner.id] = runner
                    })
                }
            }
            this.runnerCache = { ...this.runnerCache, ...newRunnerCached }
        },
        /**
         * Update or add new runner in cache
         * @param {OBR.Race.Runner} runner
         */
        onAddRunnerToCache(runner: OBR.Race.Runner) {
            this.runnerCache = { ...this.runnerCache, [runner.id]: runner }
        },
        onSetSelectedMarket(payload: OBR.Betting.MarketBetType | '') {
            this.selectedMarket = payload
        },
        //  FAVORITE SECTIONS
        async loadFavouritesByRaceId(raceID: string) {
            const favourites =
                await FavouritesService.getInstance().getAllByRaceId(raceID)

            this.setRaceFavourites({ raceID, favourites })
        },
        async setFavourite(
            idRace: string,
            payload: OBR.Favourites.FavouriteDto
        ) {
            // favourite from store
            const favourite = this.favouritesMap[idRace]?.find(
                ({ id_subject }) => id_subject === payload.id_subject
            )
            // optimistic data, update frontend instantly
            const optimistic: OBR.Favourites.RaceFavourite = {
                id_subject: payload.id_subject,
                email_alert:
                    favourite?.email_alert !== undefined
                        ? favourite.email_alert
                        : true,
                note: favourite?.note || '',
            }

            if (payload.note !== undefined) {
                optimistic.note = payload.note
            }

            if (payload.email_alert !== undefined) {
                optimistic.email_alert = payload.email_alert
            }

            if (this.favouritesMap[idRace]) {
                const favouriteIndex = this.favouritesMap[idRace]?.findIndex(
                    ({ id_subject }) => id_subject === optimistic.id_subject
                )

                if (favouriteIndex !== -1) {
                    if (
                        optimistic.note === '' &&
                        optimistic.email_alert === false
                    ) {
                        // delete favourite in case we both note and email alert have default value
                        // i.e. note is empty and star is not selected
                        this.deleteFavourite(idRace, payload.id_subject)
                        return
                    }

                    // update favourite in race store
                    this.favouritesMap[idRace][favouriteIndex] = optimistic
                } else {
                    // push new favourite to race in store
                    this.favouritesMap[idRace].push(optimistic)
                }
            } else {
                this.favouritesMap[idRace] = [optimistic]
            }

            return FavouritesService.getInstance().setFavourite(payload)
        },
        async deleteFavourite(idRace: string, idSubject: string) {
            this.favouritesMap[idRace] = this.favouritesMap[idRace]?.filter(
                ({ id_subject }) => id_subject !== idSubject
            )

            return FavouritesService.getInstance().deleteFavourite(idSubject)
        },
        setRaceFavourites({
            raceID,
            favourites,
        }: {
            raceID: string
            favourites: OBR.Favourites.RaceFavourite[]
        }) {
            this.favouritesMap = {
                ...this.favouritesMap,
                [raceID]: favourites,
            }
        },
        resetFavourites() {
            this.favouritesMap = {}
        },
        onUpdateFixedOddsForRunner(
            idRace: string,
            idRunner: string,
            fxw: number,
            fxp: number
        ) {
            const raceCopy = JSON.parse(JSON.stringify(this.racesCache[idRace]))

            for (const idxRunner in raceCopy.runners) {
                if (raceCopy.runners[idxRunner].id === idRunner) {
                    raceCopy.runners[idxRunner].odds_fxw = fxw
                    raceCopy.runners[idxRunner].odds_fxp = fxp
                }
            }

            this.onSetRace(raceCopy)
        },

        /**
         * ---------------------------
         * H2H race and runner section
         * ---------------------------
         */

        /**
         * Get Upcoming H2H races
         * e.g. for widget on main page and upcoming H2H races page
         */
        onRacesH2HUpcoming(): Promise<OBR.Race.Race[]> {
            const racePromise: Promise<OBR.Race.Race[]> = cacheService
                .doCall(RACES_H2H_UPCOMING, {})
                .then((races) => {
                    this.onSetRacesH2HInStore(races)

                    return races || []
                })
            return racePromise
        },

        /**
         * Get H2H race for parent (normal) race
         * e.g. for H2H tab on racecard
         */
        onRacesH2HByRaceID(id: string, noCache: boolean = false) {
            // get from cache only h2h races with runners - from betslip h2h races dont have all runners
            const _races = Object.keys(this.racesCache)
                .filter((key) => {
                    return (
                        this.racesCache[key].id_race_parent === id &&
                        this.racesCache[key].special_type ===
                            BetSpecialType.H2H &&
                        this.racesCache[key].runners.length
                    )
                })
                .map((key: string) => this.racesCache[key])

            const racePromise = raceService
                .getRacesH2HByRaceId(id)
                .then((races) => {
                    if (!races) return []
                    this.onSetRacesH2HInStore(races)
                    return races || []
                })

            if (noCache) {
                return racePromise
            }

            return _races.length ? Promise.resolve(_races) : racePromise
        },

        /**
         * H2H race and runner section
         */
        onAddRunnerH2HToStore(runner: OBR.Race.Runner, raceID: string) {
            if (this.racesCache[raceID]?.runners) {
                const runnersH2H = this.racesCache[raceID].runners
                if (runnersH2H) {
                    for (const key in this.racesCache[raceID].runners) {
                        if (runnersH2H[key].id === runner.id) {
                            runnersH2H[key] = runner
                            break
                        }
                    }
                }
            }

            this.runnerCache[runner.id] = { ...runner }
        },

        onSetRacesH2HInStore(races: OBR.Race.Race[]) {
            races?.forEach((race: OBR.Race.Race) => {
                this.racesCache[race.id] = race
                this.onSetRunnersH2HInRunnersH2HStore(race.runners)
            })

            this.racesCache = { ...this.racesCache }
        },
        onSetRunnersH2HInRunnersH2HStore(runners: OBR.Race.Runner[]) {
            runners.forEach((runner) => {
                this.runnerCache[runner.id] = runner
            })
        },

        onSetRacesSpecialInStore(races: OBR.Race.Race[]) {
            races?.forEach((race: OBR.Race.Race) => {
                this.racesCache[race.id] = race
            })

            this.racesCache = { ...this.racesCache }
        },
    },
})
