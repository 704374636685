let cache: { [key: string]: any } = {}

function setItem(key: string, value: any) {
    cache[key] = value
}

function getItem(key: string): any {
    return cache[key]
}

function reset() {
    cache = {}
}

export default {
    setItem,
    getItem,
    reset,
}
