import { defineStore } from 'pinia'

import { CacheService } from '@obr-core/services/CacheService'
import { BonusService } from '@obr-core/services/api'
import { BONUSES_BONUS_DETAILS } from '@obr-core/services/CacheService/resolvers/bonuses'
import { setItem } from '@obr-core/lib/storage.manager'
import { STORAGE_KEY_FREEBETS_BANNER_SHOWN } from '@obr-core/config/storage'
import { initialState } from './state'

const cacheService = CacheService.getInstance()
const bonusService = BonusService.getInstance()

export const useBonuses = defineStore('obr-store/bonuses', {
    state: initialState,
    actions: {
        /**
         * load bonus overview
         * @returns {Promise<OBR.Bonuses.BonusesOverview | null>}
         */
        async onLoadBonusOverview(): Promise<OBR.Bonuses.BonusesOverview | null> {
            this.onSetLoadingOverview(true)
            const bonusOverview: OBR.Bonuses.BonusesOverview | null =
                await bonusService
                    .getBonusesOverview()
                    .finally(() => this.onSetLoadingOverview(false))

            if (bonusOverview) {
                this.onSetCurrentBonus(
                    bonusOverview.current_bonus || { bonuses: [], chunk: null }
                )
                this.onSetFreebets(bonusOverview.bonus_list?.freebets)
                this.onSetPendingBonuses(
                    bonusOverview.bonus_list?.pending_bonuses
                )
                this.onSetHistory(bonusOverview.bonus_list?.history)
            } else {
                this.onSetCurrentBonus({ bonuses: [], chunk: null })
                this.onSetFreebets([])
                this.onSetPendingBonuses([])
                this.onSetHistory([])
            }

            return bonusOverview
        },
        /**
         * set Claim bonus
         * @param {string} id code of new bonus
         * @returns {Promise<OBR.Bonuses.ClaimBonusPayload>}
         */
        async onDoClaimBonus(
            id: string
        ): Promise<OBR.Bonuses.ClaimBonusPayload> {
            const response = await bonusService.claimBonus(id)
            if (response.bonus_list) {
                this.onSetFreebets(response.bonus_list.freebets || [])
                this.onSetPendingBonuses(
                    response.bonus_list.pending_bonuses || []
                )
                this.onSetHistory(response.bonus_list.history || [])
            }
            return response
        },
        /**
         * load Bonus details
         * @param {string} id idBonusChunk
         * @returns {Promise<OBR.Bonuses.BonusDetails[]>}
         */
        onLoadBonusDetails(id: number): Promise<OBR.Bonuses.BonusDetails[]> {
            return cacheService.doCall(BONUSES_BONUS_DETAILS, {
                args: id,
                cacheable: true,
            })
        },
        /**
         * Redeem Bonus
         * @param {number[]} bonus_ids
         * @returns {Promise<OBR.Bonuses.BonusRedeemPayload>}
         */
        async onDoRedeemBonus(
            bonus_ids: number[]
        ): Promise<OBR.Bonuses.BonusRedeemPayload> {
            const response = await bonusService.redeemBonus(bonus_ids)
            // update overview bonus data and clean history cache for future call
            if (response?.success) {
                this.onLoadBonusOverview()
            }

            return response
        },
        onSetLoadingOverview(status: boolean) {
            this.loadingOverview = status

            if (status === false) {
                this.loadedOverview = true
            }
        },
        onSetCurrentBonus({
            bonuses = [],
            chunk = null,
        }: {
            bonuses: OBR.Bonuses.Bonuses[]
            chunk: OBR.Bonuses.BonusChunk | null
        }) {
            this.current_bonus = {
                bonuses,
                chunk,
            }
        },
        onSetFreebets(freebets: OBR.Bonuses.Freebet[] = []) {
            this.freebets = freebets
        },
        onSetPendingBonuses(pending_bonuses: OBR.Bonuses.PendingBonus[] = []) {
            this.pending_bonuses = pending_bonuses
        },
        onSetHistory(histories: OBR.Bonuses.History[] = []) {
            this.histories = histories
        },
        /**
         * update freebet by balance
         * @param balance
         */
        onSetFreebetsFromBalance(
            balance: OBR.User.Balance | OBR.WebSockets.BalanceInfo
        ) {
            const freebets: OBR.Bonuses.Freebet[] =
                balance.freebets?.map((freebet) => ({
                    ...freebet,
                    promotion_code: '',
                })) || []
            this.onSetFreebets(freebets)
            this.racesWithFreebets = balance.races_with_freebets || []
        },
        onHideFreebetsBanner() {
            // save banner shown flag to storage that expires in 1 day
            this.isFreebetsBannerShown = false
            const now = new Date()
            now.setDate(now.getDate() + 1)

            setItem(STORAGE_KEY_FREEBETS_BANNER_SHOWN, false, now.getTime())
        },

        onResetBonuses() {
            this.$reset()
        },
    },
})
