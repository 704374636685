import { defineStore } from 'pinia'

import { getTimestamp } from '@obr-core/helpers/generic.helpers'
import { removeItem, setItem } from '@obr-core/lib/storage.manager'
import {
    prepareMultiplesToRemove,
    getUniqueMultiplesRacesNumber,
    isMaxNumberOfRacesReached,
    isBetFromUniqueRace,
    getBetIndexInBetslip,
    getSingleBetWithRmsResponse,
    getMultipleBetWithRmsResponse,
    getBetRunnerId,
    getUniqueRacesIdFromBets,
    getBetRunnersId,
    generateSelectionsForSingleBet,
    getBetRaceId,
    getPickBetRunnersId,
    validateBetsFromLocalStorage,
} from '@obr-core/helpers/betslip.helpers'
import { debounce } from '@obr-ui/utils/debounce'
import { BetslipService } from '@obr-core/services/api'
import {
    betslipStoreService,
    raceStoreService,
    userStoreService,
} from '@obr-core/services/store'
import { RunnerWebSocketsService } from '@obr-core/services/websockets/RunnerWebSocketsService'
import { generateId } from '@obr-ui/utils/utils'
import { BetslipSubtab } from '@obr-ui/components/Betslip/config'
import { initialState } from './state'
import { BetCategory, BetSpecialType, BetType } from '@obr-core/config/betting'
import {
    BETSLIP_STORAGE_KEY_PICKBETS,
    BETSLIP_STORAGE_KEY_PICKBETS_ID,
    BETSLIP_STORAGE_KEY_RACES,
} from '@obr-core/config/betslip'
import { renderAddToBetslipAnimation } from '@obr-ui/components/RaceCard/helpers'

const betslipService = BetslipService.getInstance()

export const useBetslip = defineStore('obr-store/betslip', {
    state: initialState,
    actions: {
        onLoad() {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            const result = validateBetsFromLocalStorage(betslipService.get())

            if (!userStoreService.isLoggedIn()) {
                this.onRemoveAllFreebetsIdsAndRms()
            }

            if (result?.races?.length) {
                result.races.forEach((race: OBR.Race.Race) => {
                    raceStoreService.addRaceToStore(
                        race,
                        !!raceStoreService.raceByCache(`${race.id}`)
                    )
                })
            }

            // Save partial runners connected to bets
            if (result?.races.length) {
                result.races.forEach((race) => {
                    race.runners.forEach((runner: OBR.Race.Runner) => {
                        raceStoreService.addRunnerToStore(
                            runner,
                            runner.id_race
                        )
                    })
                })
            }

            // set single bets form saved in betslip
            this.onSetBets(result.betslip.bets)
            // set multiples bets form saved in betslip
            this.onSetMultiples(result.betslip.multiples)
            // set pickbet
            if (result.betslip.pickBets && result.betslip.activePickBet) {
                this.onSetPickBets({
                    pickBetSelections: result.betslip.pickBets,
                    id: result.betslip.activePickBet,
                })

                // Listen socket for pick bets
                const pickBet =
                    result.betslip.pickBets[result.betslip.activePickBet]

                getPickBetRunnersId(pickBet).forEach((runnerId) => {
                    runnerWebSocketsService.joinBetslipRunnerChannel(
                        `${runnerId}`,
                        getTimestamp()
                    )
                })
            }

            // Listen socket
            result.betslip.bets.forEach((bet: OBR.Betting.Bet) => {
                getBetRunnersId(bet).forEach((id) =>
                    runnerWebSocketsService.joinBetslipRunnerChannel(
                        `${id}`,
                        getTimestamp()
                    )
                )
            })
        },
        async onUpdate(betslip: {
            bets: OBR.Betting.Bet[]
            multiples: OBR.Betting.Multiple[]
        }) {
            this.onSetBets(betslip.bets)
            this.onSetMultiples(betslip.multiples)

            const isSomeRunnerScratched = betslip.bets.some((bet) => {
                const runner = raceStoreService.runnerByCache(
                    getBetRunnerId(bet)
                )
                return runner?.scratched
            })

            if (isSomeRunnerScratched) {
                return
            }

            await this.onUpdateBetslip()
        },
        async onSave(
            exoticBet?: OBR.Betting.Bet | null,
            activeSubtab?: BetslipSubtab
        ) {
            if (exoticBet) {
                return await betslipService.save({
                    bets: [exoticBet],
                    hasChange: false,
                    potentialBet: null,
                    isInstant: true,
                    multiples: [],
                    pickBets: {},
                    activePickBet: '',
                    // for exotic multiple bet type is irrelevant
                    multipleBetType: BetType.WIN,
                    dialog: {
                        isVisible: false,
                    },
                    editedExoticBetUid: null,
                    pickBetEvetntId: null,
                    currencyHasChanged: false,
                    hasUnmatchedOdds: false,
                })
            }
            if (activeSubtab === BetslipSubtab.MULTIPLES) {
                // in case of multiple bet type, we need to make deep copy of betslip
                const copy = JSON.parse(JSON.stringify(this.$state))

                copy.bets = copy.bets
                    .filter((bet: OBR.Betting.Bet) => bet.in_multiples)
                    // filter out bets which are not in multiples (excluded)
                    .map((bet: OBR.Betting.Bet) => {
                        const race = raceStoreService.raceByCache(
                            getBetRaceId(bet)
                        )
                        const runner = raceStoreService.runnerByCache(
                            getBetRunnerId(bet)
                        )

                        if (!race || !runner) {
                            return
                        }
                        return {
                            ...bet,
                            selections: generateSelectionsForSingleBet(
                                race.id,
                                runner,
                                betslipStoreService.multipleBetType()
                            ),
                            // change market of each bet to global multiple market
                            market: betslipStoreService.multipleBetType(),
                            // if bet type is WIN or EACHWAY, use orginal category
                            // otherwise use always BOOKIE
                            category: [BetType.WIN, BetType.EACHWAY].includes(
                                betslipStoreService.multipleBetType()
                            )
                                ? bet.category_multi
                                : BetCategory.BOOKIE,
                            // remove all id freebet
                            id_freebet: null,
                            // remove all stakes - we do not save single bets
                            unit_stake: null,
                        }
                    })

                return betslipService.save(copy, true)
            } else if (activeSubtab === BetslipSubtab.SINGLES) {
                // before sending freebet to server divide unit stake by 2
                const copy = { ...this.$state }
                copy.bets = copy.bets.map((bet) => {
                    const betCopy = { ...bet }
                    if (
                        betCopy.id_freebet &&
                        betCopy.is_each_way &&
                        betCopy.unit_stake
                    ) {
                        betCopy.unit_stake = betCopy.unit_stake / 2
                    }

                    return betCopy
                })
                // remove all multiples - we do not save multiples from single bets tab
                copy.multiples = []
                return betslipService.save(copy)
            }

            return betslipService.save(this.$state)
        },
        async onSmsResult(publicIds: string[], mobile: string) {
            await betslipService.smsResult(publicIds, mobile)
        },
        onSaveRace(race: OBR.Race.Race, isPickBet = false) {
            betslipService.updateRaces(race, isPickBet)
        },
        async onAddBet(bet: OBR.Betting.Bet) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            if (
                isMaxNumberOfRacesReached(this.bets) &&
                isBetFromUniqueRace(this.bets, bet)
            )
                return

            // if exotic bet is already in betslip update it instead of adding new
            if (
                bet.special_type === BetSpecialType.EXOTIC &&
                this.bets.some((betInBetslip) => betInBetslip.uid === bet.uid)
            ) {
                return this.onUpdateExoticBet(bet)
            }

            await this.onUpdate({
                bets: [...this.bets, bet],
                multiples: [...this.multiples],
            })

            // listen socket
            getBetRunnersId(bet).forEach((runnerId) => {
                runnerWebSocketsService.joinBetslipRunnerChannel(
                    `${runnerId}`,
                    getTimestamp()
                )
            })
        },
        async onUpdateExoticBet(bet: OBR.Betting.Bet) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            let oldBetQniqueIds: string[] = []
            const newBetUniqueIds = getBetRunnersId(bet)

            const allBets = this.bets.map((betInBetslip) => {
                if (betInBetslip.uid === bet.uid) {
                    oldBetQniqueIds = getBetRunnersId(betInBetslip)
                    return bet
                }
                return betInBetslip
            })

            await this.onUpdate({
                bets: [...allBets],
                multiples: [...this.multiples],
            })

            const result = [...oldBetQniqueIds, ...newBetUniqueIds].reduce(
                (acc: { leave: string[]; join: string[] }, id: string) => {
                    if (
                        oldBetQniqueIds.includes(id) &&
                        !newBetUniqueIds.includes(id)
                    ) {
                        acc.leave.push(id)
                    } else if (
                        newBetUniqueIds.includes(id) &&
                        !oldBetQniqueIds.includes(id)
                    ) {
                        acc.join.push(id)
                    }
                    return acc
                },
                { leave: [], join: [] }
            )

            result.leave.forEach((id) =>
                runnerWebSocketsService.leaveBetslipRunnerChannel(id)
            )

            result.join.forEach((id) =>
                runnerWebSocketsService.joinBetslipRunnerChannel(
                    `${id}`,
                    getTimestamp()
                )
            )
        },
        async onUpdateBet(payload: { bet: OBR.Betting.Bet; index: number }) {
            const oldBetsCount = getUniqueMultiplesRacesNumber(this.bets)
            this.onSetBet(payload)

            if (getUniqueMultiplesRacesNumber(this.bets) !== oldBetsCount) {
                prepareMultiplesToRemove(oldBetsCount).forEach((payload) => {
                    this.onUnsetMultiple(payload)
                })
            }
            debounce(() => {
                const runner = raceStoreService.runnerByCache(
                    getBetRunnerId(payload.bet)
                )
                const races = getUniqueRacesIdFromBets(this.bets).map(
                    (idRace: string) => {
                        return raceStoreService.raceByCache(idRace)
                    }
                )

                // skip update if there are runners which are scratched
                if (runner?.scratched) {
                    return
                }

                betslipService.update(
                    {
                        bets: [...this.bets],
                        multiples: [...this.multiples],
                    },
                    races as OBR.Race.Race[]
                )
            })
        },
        onRemoveBet(index: number) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            const oldBetsCount = getUniqueMultiplesRacesNumber(this.bets)
            const inMultiples = this.bets[index].in_multiples

            const bet = this.bets[index]

            this.onUnsetBet(index)

            if (
                inMultiples &&
                getUniqueMultiplesRacesNumber(this.bets) !== oldBetsCount
            ) {
                prepareMultiplesToRemove(oldBetsCount).forEach((payload) => {
                    this.onUnsetMultiple(payload)
                })
            }

            this.onUpdateBetslip()

            runnerWebSocketsService.leaveBetslipRunnerChannel(
                getBetRunnerId(bet)
            )
        },
        async onRemoveAllBets(publicIds?: string[]) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            this.bets.forEach((bet) => {
                getBetRunnersId(bet).forEach((id) =>
                    runnerWebSocketsService.leaveBetslipRunnerChannel(id)
                )
            })

            if (publicIds) {
                this.bets = this.bets.filter(
                    (bet) => !publicIds.includes(bet.uid)
                )
                this.multiples = this.multiples.filter(
                    (bet) => !publicIds.includes(bet.uid)
                )
            } else {
                this.onUnsetAllBets()
            }

            this.onUpdateBetslip()
        },
        async removeBetsByIndex(indexes: number[]) {
            if (!indexes.length) return
            const oldState = {
                bets: [...this.bets],
                multiples: [...this.multiples],
            }

            const updatedBets = this.bets.filter(
                (bet, index) => !indexes.includes(index)
            )

            this.bets = updatedBets
            this.multiples = []
            await this.onUpdateBetslip()

            // grab ids of runners which should still remain connected to their ws channel
            const idRunners: string[] = updatedBets.map((b) =>
                getBetRunnerId(b)
            )
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            // disconnect from the ws channels of runners removed from the betslip
            oldState.bets
                .filter((b) => !idRunners.includes(getBetRunnerId(b)))
                .forEach((b) => {
                    runnerWebSocketsService.leaveBetslipRunnerChannel(
                        getBetRunnerId(b)
                    )
                })
        },
        async onAddMultples(payload: OBR.Betting.Multiple) {
            const oldState = {
                bets: [...this.bets],
                multiples: [...this.multiples],
            }

            this.onSetNewMultiple(payload)

            const isSomeRunnerScratched = oldState.bets.some((bet) => {
                const runner = raceStoreService.runnerByCache(
                    getBetRunnerId(bet)
                )
                return runner?.scratched
            })

            if (isSomeRunnerScratched) {
                return
            }

            await this.onUpdateBetslip()
        },
        async onUpdateMultples(payload: OBR.Betting.Multiple) {
            const oldState = {
                bets: [...this.bets],
                multiples: [...this.multiples],
            }

            this.onSetMultiple(payload)

            debounce(async () => {
                const isSomeRunnerScratched = oldState.bets.some((bet) => {
                    const runner = raceStoreService.runnerByCache(
                        getBetRunnerId(bet)
                    )
                    return runner?.scratched
                })

                if (isSomeRunnerScratched) {
                    return
                }

                await this.onUpdateBetslip()
            })
        },
        async onRemoveMultiples(payload: {
            system: OBR.Betting.System
            baseBetsCount: number
        }) {
            const oldState = {
                bets: [...this.bets],
                multiples: [...this.multiples],
            }

            this.onUnsetMultiple(payload)

            const isSomeRunnerScratched = oldState.bets.some((bet) => {
                const runner = raceStoreService.runnerByCache(
                    getBetRunnerId(bet)
                )
                return runner?.scratched
            })

            if (isSomeRunnerScratched) {
                return
            }

            await this.onUpdateBetslip()
        },
        async onUpdateBetslip() {
            const races = getUniqueRacesIdFromBets(this.bets).map(
                (idRace: string) => {
                    return raceStoreService.raceByCache(idRace)
                }
            )

            betslipService.update(
                {
                    bets: [...this.bets],
                    multiples: [...this.multiples],
                },
                races as OBR.Race.Race[]
            )
        },
        async onValidateBetslip() {
            betslipService.validate({
                bets: [...this.bets],
                multiples: [...this.multiples],
            })
        },
        onSetBets(bets: OBR.Betting.Bet[]) {
            this.bets = bets
        },
        onSetMultiples(multiples: OBR.Betting.Multiple[]) {
            this.multiples = multiples
        },
        onSetBet(payload: { bet: OBR.Betting.Bet; index: number }) {
            this.bets[payload.index] = payload.bet
        },
        onUnsetBet(index: number) {
            this.bets = this.bets.filter((el, idx) => index !== idx)
        },
        onUnsetAllBets() {
            this.bets = []
            this.multiples = []
        },
        onSetNewMultiple(payload: OBR.Betting.Multiple) {
            this.multiples.push({ ...payload })
        },
        onSetMultiple(payload: OBR.Betting.Multiple) {
            this.multiples.map((multiple) => {
                if (
                    multiple.system === payload.system &&
                    multiple.base_bets_count === payload.base_bets_count
                ) {
                    multiple.unit_stake = payload.unit_stake
                    multiple.is_each_way = payload.is_each_way
                    multiple.num_bets = payload.num_bets
                }

                return multiple
            })
        },
        onUnsetMultiple(payload: {
            system: OBR.Betting.System
            baseBetsCount: number
        }) {
            this.multiples = this.multiples.filter((multiple) => {
                return !(
                    multiple.system === payload.system &&
                    multiple.base_bets_count === payload.baseBetsCount
                )
            })
        },
        onUnsetAllMultiples() {
            this.multiples = []
        },
        onSetHasChange(status: boolean) {
            this.hasChange = status
        },
        onSetHasUnmatchedOdds(status: boolean) {
            this.hasUnmatchedOdds = status
        },
        onSetCurrencyHasChanged(status: boolean) {
            this.currencyHasChanged = status
        },
        onSetPotentialBet(payload: OBR.Betting.PotentialBet | null) {
            this.potentialBet = payload
        },
        onSetRmsResponse(
            isMultiple: boolean,
            betNumber: number,
            idRms: number,
            type: OBR.Betting.RmsError,
            newUnitStake: number = 0
        ) {
            if (isMultiple) {
                this.multiples[betNumber] = getMultipleBetWithRmsResponse(
                    this.multiples[betNumber],
                    idRms,
                    type,
                    newUnitStake
                )
            } else {
                const newIndex = getBetIndexInBetslip(this.bets, betNumber)

                if (this.bets[newIndex]) {
                    this.bets[newIndex] = getSingleBetWithRmsResponse(
                        this.bets[newIndex],
                        idRms,
                        type,
                        newUnitStake
                    )
                }
            }
        },

        onAcceptRms(idRms: number) {
            this.bets.forEach((bet, index) => {
                if (bet.rms_response?.id_rms === idRms) {
                    this.bets[index] = {
                        ...bet,
                        rms_response: { ...bet.rms_response, accepted: true },
                    }
                }
            })
            this.multiples.forEach((bet, index) => {
                if (bet.rms_response?.id_rms === idRms) {
                    this.multiples[index] = {
                        ...bet,
                        rms_response: { ...bet.rms_response, accepted: true },
                    }
                }
            })
        },

        onAcceptAllRms() {
            const newBets = this.bets.map((bet: OBR.Betting.Bet) => {
                return bet.rms_response?.accepted === false
                    ? {
                          ...bet,
                          rms_response: { ...bet.rms_response, accepted: true },
                      }
                    : {
                          ...bet,
                      }
            })
            this.bets = newBets

            const newMulti = this.multiples.map((bet: OBR.Betting.Multiple) => {
                return bet.rms_response?.accepted === false
                    ? {
                          ...bet,
                          rms_response: { ...bet.rms_response, accepted: true },
                      }
                    : {
                          ...bet,
                      }
            })
            this.multiples = newMulti
        },

        onRmoveAllRmsData() {
            this.bets = this.bets.map((bet) => {
                delete bet.rms_response
                delete bet.id_rms
                return bet
            })
            this.multiples = this.multiples.map((bet) => {
                delete bet.rms_response
                delete bet.id_rms
                return bet
            })
        },

        onRemoveAllFreebetsIdsAndRms() {
            this.bets = this.bets.map((bet) => {
                if (bet.id_freebet || bet.id_rms) {
                    bet.id_freebet = null
                    bet.id_rms = undefined
                    bet.unit_stake = undefined
                    bet.uid = generateId()
                    delete bet.rms_response
                }

                return bet
            })
        },
        onUpdateMultiBetType(type: OBR.Betting.BetType) {
            this.multipleBetType = type
            this.multiples.map((bet) => (bet.is_each_way = false))
        },

        onSetPickBet(payload: {
            id: string
            pickBetSelection: OBR.Betting.PickBetSelection
        }) {
            this.activePickBet = payload.id
            this.pickBets = {
                ...this.pickBets,
                [payload.id]: payload.pickBetSelection,
            }
            setItem(BETSLIP_STORAGE_KEY_PICKBETS, this.pickBets)
            setItem(BETSLIP_STORAGE_KEY_PICKBETS_ID, this.activePickBet)
        },

        onSetPickBets(payload: {
            id: string
            pickBetSelections: OBR.Common.Object<OBR.Betting.PickBetSelection>
        }) {
            this.activePickBet = payload.id
            this.pickBets = payload.pickBetSelections
        },

        onUnsetPickBetLeg(payload: { pickBetId: string; legId: string }) {
            const pickBet = this.pickBets[payload.pickBetId]

            if (!pickBet) {
                return
            }

            pickBet[payload.legId].runners = {}
        },

        onUnsetAllPickBetLegs(payload: { pickBetId: string }) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            const { [payload.pickBetId]: pickBet, ...rest } = this.pickBets

            // leave socket
            getPickBetRunnersId(pickBet).forEach((runnerId) => {
                runnerWebSocketsService.leaveBetslipRunnerChannel(runnerId)
            })

            removeItem(BETSLIP_STORAGE_KEY_PICKBETS)
            removeItem(BETSLIP_STORAGE_KEY_PICKBETS_ID)
            removeItem(BETSLIP_STORAGE_KEY_RACES)
            this.pickBets = rest
            this.activePickBet = ''
        },

        onSetActivePickBet(id: string) {
            this.activePickBet = id
        },

        onUpdatePickBetLeg(payload: {
            idPickBet: string
            idLeg: string
            runner: OBR.Betting.PickBetLegRunner
            force_update?: boolean
        }) {
            const runnerWebSocketsService =
                RunnerWebSocketsService.getInstance()
            const pickBet = this.pickBets?.[payload.idPickBet]
            if (!pickBet) return

            const leg = pickBet?.[payload.idLeg]
            if (!leg) return

            const runner = leg?.runners?.[payload.runner.program_number]

            if (!runner) {
                // add runner to leg
                leg.runners[payload.runner.program_number] = payload.runner

                renderAddToBetslipAnimation(payload.runner.id_runner)

                // listen socket
                runnerWebSocketsService.joinBetslipRunnerChannel(
                    `${payload.runner.id_runner}`,
                    getTimestamp()
                )
            } else {
                if (runner.excluded && !payload.force_update) {
                    runner.excluded = false
                } else {
                    // remove runner from leg
                    delete leg.runners[payload.runner.program_number]

                    // leave socket
                    runnerWebSocketsService.leaveBetslipRunnerChannel(
                        `${payload.runner.id_runner}`
                    )
                }
            }
            setItem(BETSLIP_STORAGE_KEY_PICKBETS, this.pickBets)
            setItem(BETSLIP_STORAGE_KEY_PICKBETS_ID, this.activePickBet)
        },

        onSetPickBetLegExcludedRunner(payload: {
            idPickBet: string
            idLeg: string
            runnerNumber: number
        }) {
            const pickBet = this.pickBets?.[payload.idPickBet]
            if (!pickBet) return

            const leg = pickBet?.[payload.idLeg]
            if (!leg) return

            const runner = leg?.runners?.[payload.runnerNumber]
            if (!runner) return

            runner.excluded = !runner.excluded
        },

        onRemovePickBetLegScratchRunner(payload: {
            idPickBet: string
            idRunners: string[]
        }): void {
            const pickBet = this.pickBets?.[payload.idPickBet]
            if (!pickBet) return

            const legs = Object.values(pickBet)
            legs.forEach((leg) => {
                const runners = Object.values(leg.runners)
                runners.forEach((runner) => {
                    if (payload.idRunners.includes(runner.id_runner)) {
                        delete leg.runners[runner.program_number]
                    }
                })
            })

            setItem(BETSLIP_STORAGE_KEY_PICKBETS, this.pickBets)
        },

        onUpdateDialog(payload: {
            isVisible: boolean
            status?: 'primary' | 'action'
            title?: string
            message?: string
            confirm?: string
            cancel?: string
            actionConfirm?: () => void
            actionCancel?: () => void
        }) {
            this.dialog = {
                ...payload,
            }
        },

        onUpdateMultiBetCategory(
            category: OBR.Betting.BetCategory,
            index: number
        ) {
            this.bets[index].category_multi = category
        },
        onSetEditedExoticBetUid(uid?: string) {
            this.editedExoticBetUid = uid ? uid : null
        },
    },
})
