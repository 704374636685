import { i18n } from '@obr-core/i18n/i18n'
import { ModalService } from '@obr-core/services/ModalService'
import {
    betslipStoreService,
    raceStoreService,
    userStoreService,
    appStoreService,
} from '@obr-core/services/store'
import { getBetRaceId, getBetRunnerId } from '@obr-core/helpers/betslip.helpers'
import { RmsError } from '@obr-core/config/betslip'
import { formatToLocaleNumberWithCurrency } from '@obr-core/utils/number.format'
import { formatOdds } from '@obr-core/helpers/odds.helpers'
import { LimitType } from '@obr-core/config/restrictions'
import { BetCategory } from '@obr-core/config/betting'
import { ModalSimpleStatus } from '@obr-core/config/modal'
import { isIframe } from '@obr-core/helpers/app.helpers'
import { postMessageService } from '@obr-core/services/PostMessageService'
import { POST_MESSAGE_ID_RELOAD } from '@obr-core/config/post-messages'

const enum DialogType {
    CONFIRMATION = 'confirmation',
    ERROR = 'error',
}
/**
 * Handle authentication error before
 */
export function onPlaceBetslipError(
    error: OBR.Errors.BetslipParsedPostErrorItem[],
    isMultiple: boolean
): {
    message?: string | undefined
    type?: DialogType | undefined
    title?: string | undefined
    buttonText?: string | undefined
    linkText: string
    triggerEvent?: (() => void) | undefined
} | null {
    const errorObj = error.length
        ? error.map((errorItem) => processError(errorItem, isMultiple))
        : [getDefaultMessage(undefined)]

    if (errorObj[0]?.message) {
        if (errorObj[0].type !== DialogType.CONFIRMATION) {
            ModalService.getInstance().displayStandardModal(
                errorObj[0].message,
                '',
                ModalSimpleStatus.ERROR,
                errorObj[0].triggerEvent ? errorObj[0].triggerEvent : () => {},
                errorObj[0].triggerEvent ? errorObj[0].triggerEvent : () => {}
            )
        } else {
            ModalService.getInstance().confirmationModal({
                message: errorObj[0].message,
                title: errorObj[0].title,
                status: 'primary',
                buttonText: errorObj[0].buttonText,
                linkText: errorObj[0].linkText,
                onClickButton: errorObj[0].triggerEvent,
            })
        }
    }

    return errorObj[0]
}

function processError(
    error: OBR.Errors.BetslipParsedPostErrorItem,
    isMultiple: boolean
): {
    message?: string
    type?: DialogType
    title?: string
    buttonText?: string
    linkText: string
    triggerEvent?: () => void
} | null {
    const result = getDefaultMessage(error.error_code)

    switch (error.error_code) {
        case 62: // general tote error
        case 113: // can not connect with easygate server
        case 114: // wrong easygate response
        case 115: // easygate returned error
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_tote_error', {
                    errorCode: error.error_code,
                }),
            })

        case 97: // TOT blocked
        case 124: // not allowed to play TOT from your country (ATG restrictions)
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_tote_blocked_in_country', {
                    ipCountry: i18n.global.t(
                        `generic.countries.${appStoreService.ipCountry()}`
                    ),
                }),
            })

        case 101: // session invalid
            return Object.assign(result, {
                message: i18n.global.t('generic.label_try_again'),
                triggerEvent: () => {
                    window.location.reload()
                },
            })

        case 103: // not enough money
            return Object.assign(result, {
                message: i18n.global.t('generic.msg_insufficient_funds'),
                triggerEvent: () => {
                    ModalService.getInstance().closeConfirmationModal()
                    userStoreService.depositRequired()
                },
                type: DialogType.CONFIRMATION,
                title: i18n.global.t('generic.h_attention'),
                buttonText: i18n.global.t('generic.label_deposit'),
                linkText: i18n.global.t('generic.label_cancel'),
            })

        case 105: // xtc race closed
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_race_closed'),
            })

        case 106: // race not open
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_race_started'),
            })

        case 107: // stakes limit for this race is reached
        case 108: // runner stake limit is reached
        case 109: // runner payout limit is reached
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_limit_reached'),
            })

        case 110: // betX generic error
            return result
        case 111: // fixed odds win do not match
        case 112: {
            // fixed odds place do not match

            handleFixedOddsChangedError(error)
            return null
        }
        case 116: // customer restrictions error
        case 145: // customer restrictions error
        case 144: //customers restrictions error
            if (error.restriction_amount && error.period_of_time) {
                return Object.assign(result, {
                    message: i18n.global.t(
                        error.restriction_type === LimitType.STAKE
                            ? 'account.msg_stake_limit'
                            : 'account.msg_loss_limit',
                        {
                            limitAmount: formatToLocaleNumberWithCurrency(
                                Number(error.restriction_amount),
                                userStoreService.currency(),
                                true
                            ),
                            period: error.period_of_time,
                        }
                    ),
                })
            }
            return result

        case 117: // customer is self-excluded via external database (i.e. Oasis)
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_account_excluded_external'),
            })
        case 118: // fixed odds are not active in this race (events_races.fixedOddsStatus != ON)
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_fixed_odds_offline'),
            })
        case 119: // self-exclusion can not be verified against external database (i.e. Oasis unavailable)
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.msg_account_excluded_external_service_unavailable'
                ),
            })
        case 120: // tote Error Combination
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_tote_error_combination'),
            })
        case 126: // not allowed to bet on this event from user's country
        case 335: // customer is not allowed to bet (noBetting property)
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.msg_bet_not_accepted_in_users_country',
                    {
                        country: i18n.global.t(
                            `generic.countries.${appStoreService.ipCountry()}`
                        ),
                    }
                ),
            })
        case 132: // deposit required to use bonuses
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_error_deposit_required'),
                triggerEvent: () => {
                    userStoreService.depositRequired()
                },
            })
        case 133: // stake exceeds withdrawable balance
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.msg_error_stake_exceeds_withdrawable_balance',
                    {
                        minOdds: formatOdds(1.5, userStoreService.oddsFormat()),
                    }
                ),
            })
        case 135: // invalid free bet
        case 137: // invalid free bet - cannot be used on that race/horse
        case 138: // invalid free bet - only one runner per free bet
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_free_bet_invalid'),
            })
        case 136: // only one free bet per race
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_free_bet_one_per_race'),
            })
        case 140: // maximum unit stake before price change - like rms error but without id rms
        case 302: // rms new unit stake
        case 304: // rms new unit stake - when the same amount is resent with the same idRms
            if (error.new_unit_stake && typeof error.bet_num === 'number') {
                betslipStoreService.addRmsLimitToBets(
                    isMultiple,
                    error.bet_num,
                    error.id_rms || 0,
                    RmsError.LIMIT,
                    error.new_unit_stake
                )

                return null
            }

            return result
        case 307: // cannot accept FXD odds - use SP for some races (accumulator)
            if (error.runners_sp) {
                const runnersToBeChangedToSP: string[] =
                    error.runners_sp.split(',')

                if (runnersToBeChangedToSP.length > 0) {
                    betslipStoreService.bets().forEach((bet, index) => {
                        if (
                            runnersToBeChangedToSP.includes(getBetRunnerId(bet))
                        ) {
                            betslipStoreService.changeBetMultipleCategoryByIndex(
                                BetCategory.BOOKIE,
                                index
                            )
                        }
                    })

                    if (
                        typeof error.bet_num === 'number' &&
                        typeof error.id_rms === 'number'
                    ) {
                        betslipStoreService.addRmsLimitToBets(
                            isMultiple,
                            error.bet_num,
                            error.id_rms,
                            RmsError.SP_ONLY
                        )
                    }
                }
            }
            return null
        case 142: // no bonus customer excluded from enhanced place markets WP betting
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.error_no_enhanced_places_markets'
                ),
            })

        case 147: // test accounts not allowed to play tote in prod
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_tote_no_test_accounts'),
            })
        case 150: // pool bet closed
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_pool_bet_closed'),
            })
        case 151: // xtc horse was scratched while user selecting his bets
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_scratched_horse'),
            })
        case 152: // xtc stake not divisible by min unit stake
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_step_size_invalid_tote'),
            })
        case 153:
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.msg_tote_blocked_customer_country',
                    {
                        country: i18n.global.t(
                            `generic.countries.${userStoreService.country()}`
                        ),
                    }
                ),
            })
        case 207: // limited access expired
            return Object.assign(result, {
                message: i18n.global.t('betslip.msg_limited_access_expired'),
            })
        case 210: // account not verified
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_account_not_verified'),
            })
        case 301: // rms bet not accepted
        case 310: // rms bet not accepted (auto decision)
        case 330: // Customer is not allowed to bet (noBetting property)
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_bet_response_rejected'),
            })
        case 306: // accept win only
            if (
                typeof error.bet_num === 'number' &&
                typeof error.id_rms === 'number'
            ) {
                betslipStoreService.addRmsLimitToBets(
                    isMultiple,
                    error.bet_num,
                    error.id_rms,
                    RmsError.WIN_ONLY
                )

                return null
            }
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_accept_win_only'),
            })
        case 309: // FXD cannot be accepted, offer SP instead
            if (
                typeof error.bet_num === 'number' &&
                typeof error.id_rms === 'number'
            ) {
                betslipStoreService.addRmsLimitToBets(
                    isMultiple,
                    error.bet_num,
                    error.id_rms,
                    RmsError.SP_ONLY
                )

                return null
            }
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_cannot_accept_FXD'),
            })
        case 345:
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_bet_type_limit_reached'),
            })
        case 714: // techsson - not allowed to use bonus on TOT
            return Object.assign(result, {
                message: i18n.global.t(
                    'betslip.msg_error_bonus_not_allowed_on_tote'
                ),
                triggerEvent: () => {
                    userStoreService.authRequired()
                },
            })
        case 741: // gaming session expired
            return Object.assign(result, {
                message: i18n.global.t('generic.msg_session_expired'),
                triggerEvent: () => {
                    if (isIframe()) {
                        postMessageService.send(POST_MESSAGE_ID_RELOAD, {})
                    } else {
                        window.parent.location.reload()
                    }
                },
            })
        case 804: // Lugas - PARALLEL_GAMING_WITH_OTHER_PROVIDER
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_lugas_other_provider'),
            })
        case 805: // Lugas - PARALLEL_GAMING_COOLING_OFF
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_lugas_cooling_off'),
            })

        case 806: // Lugas - PARALLEL_GAMING_ERROR
            return Object.assign(result, {
                message: i18n.global.t('betslip.error_lugas_other_provider'),
            })
        default:
            return result
    }
}

function getDefaultMessage(code: number | undefined) {
    return {
        message: i18n.global.t('generic.msg_unknown_error_with_errorcode', {
            errorCode: code ? code : 'n/a',
        }),
        type: DialogType.ERROR,
        triggerEvent: () => {},
        title: '',
        buttonText: '',
        linkText: '',
    }
}

function handleFixedOddsChangedError(
    error: OBR.Errors.BetslipParsedPostErrorItem
): boolean {
    if (typeof error.bet_num !== 'number' || !error.odds_fxw) {
        return false
    }

    const bet = betslipStoreService.bets()[error.bet_num]
    if (bet) {
        const idRace = getBetRaceId(bet)
        const idRunner = getBetRunnerId(bet)

        raceStoreService.updateFixedOddsForRunner(
            getBetRaceId(bet),
            getBetRunnerId(bet),
            error.odds_fxw,
            error.odds_fxp || 0
        )

        betslipStoreService.updateBetOdds(idRace, idRunner, error.bet_num)
        betslipStoreService.setHasUnmatchedOdds(true)
    }

    return true
}

export function onValidateBetslipError(
    errors: OBR.Betting.BetslipValidationError[]
) {
    errors.forEach((error: OBR.Betting.BetslipValidationError) => {
        switch (error.error_code) {
            case 106: // race not open
                if (error.id_race && error.status) {
                    raceStoreService.updateRaceStatus(
                        error.id_race,
                        error.status
                    )
                    betslipStoreService.setHasChange(true)
                }

                break
            case 111: // fixed odds  do not match
                if (
                    error.odds_fxp &&
                    error.odds_fxw &&
                    error.id_race &&
                    error.id_runner
                ) {
                    raceStoreService.updateFixedOddsForRunner(
                        error.id_race,
                        error.id_runner,
                        error.odds_fxw,
                        error.odds_fxp
                    )

                    handleFixedOddsChangedError(error)
                }
                break
            case 151: // runner scratched
                if (error.id_runner && error.id_race) {
                    const oldRunner = raceStoreService.runnerByCache(
                        `${error.id_runner}`
                    )
                    if (oldRunner) {
                        raceStoreService.addRunnerToStore(
                            { ...oldRunner, scratched: true },
                            error.id_race
                        )
                    }
                }
                break
            default:
                break
        }
    })
}
