/**
 * Storage prefix
 */
export const STORAGE_PREFIX: string = '@obr.'

/**
 * Maximum expire timestamp
 */
export const STORAGE_EXPIRE_MAX_TIMESTAMP = 8640000000000000

/**
 * Key to be used to access race types key on sessionManager
 */
export const STORAGE_KEY_ACTIVE_RACE_TYPES: OBR.Storage.Key =
    'EventBrowser/active_race_types'

/**
 * Key to be user to access hide finished events key on sessionManager
 * @memberof EventBrowser
 */
export const STORAGE_KEY_HIDE_FINISHED_EVENTS: OBR.Storage.Key =
    'EventBrowser/hide_finished_events'

/**
 * Key to be user to access hide dogs filter
 * @memberof EventBrowser
 */
export const STORAGE_KEY_HIDE_DOGS_FILTER: OBR.Storage.Key =
    'EventBrowser/hide_dogs_filter'

/**
 *  Key to be used by the sessionManger for isMeetingsTab
 */
export const STORAGE_KEY_IS_MEETINGS_TAB: OBR.Storage.Key =
    'EventBrowser/is_meetings_tab'

/**
 *  Key to be used by the sessionManger for isAntepostTab
 */
export const STORAGE_KEY_IS_ANTEPOST_TAB: OBR.Storage.Key =
    'EventBrowser/is_antepost_tab'

/**
 *  Key to be used by the sessionManger for isVirtualTab
 */
export const STORAGE_KEY_IS_VIRTUAL_TAB: OBR.Storage.Key =
    'EventBrowser/is_virtual_tab'

/**
 *  Key to be used by the sessionManger for closed countries
 */
export const STORAGE_KEY_CLOSED_COUNTRIES: OBR.Storage.Key =
    'EventBrowser/closed_countries'

/**
 *  Key to be used by the sessionManger for opened countries in case of previous dates
 */
export const STORAGE_KEY_OPENED_COUNTRIES: OBR.Storage.Key =
    'EventBrowser/opened_countries'

/**
 * Key to get access to current route
 */
export const STORAGE_KEY_CURRENT_ROUTE: OBR.Storage.Key = 'Router/current_route'

/**
 * Settings storage key
 */
export const STORAGE_KEY_SETTINGS: OBR.Storage.Key = 'Settings/user'

/**
 * Betslip / My bets active tab storage key
 */
export const STORAGE_KEY_BETSLIP_ACTIVE_TAB: OBR.Storage.Key =
    'Betslip/active_tab'

/**
 * Singles / Multi bets active subtab storage key
 */
export const STORAGE_KEY_BETSLIP_ACTIVE_SUBTAB: OBR.Storage.Key =
    'Betslip/active_subtab'

/**
 * Freebets banner shown storage key
 */
export const STORAGE_KEY_FREEBETS_BANNER_SHOWN: OBR.Storage.Key =
    'Freebets/banner_shown'

/**
 * Key to get access to media
 */
export const STORAGE_KEY_MEDIA: OBR.Storage.Key = 'Media/Streams'
