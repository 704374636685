import { APP_SSR_DATA } from '@obr-core/config/app'
import { parseRace } from '@obr-core/resources/RaceResource'
import {
    parseCalendarEvents,
    parseEvent,
    parseSpecialEvent,
} from '@obr-core/resources/EventResource'

/**
 * SSR Service to retrieve data provided by SSR.
 *
 * i.e. Once means that data will be read just once from the __INITIAL_SSR_STATE__
 */
export class SSRService {
    private static instance: SSRService
    private ssrData?: OBR.App.InitialSSRData
    // Keys that are already read once form SSR
    private keys: string[] = []

    constructor() {
        this.ssrData = (window as any)[APP_SSR_DATA]
    }

    /**
     * Return class instance
     */
    public static getInstance(): SSRService {
        if (SSRService.instance === undefined) {
            SSRService.instance = new SSRService()
        }

        return SSRService.instance
    }

    /**
     * Get bootsrap data once from SSR
     *
     * i.e. same as "/bootstrap" endpoint
     */
    getBootstrapOnce() {
        const key: string = 'BOOTSTRAP'

        if (!this.keys.includes(key) && this.ssrData?.bootstrap) {
            this.keys.push(key)

            return this.ssrData.bootstrap
        }

        return null
    }

    /**
     * Get event card data once from SSR
     *
     * i.e. same as "/events/ID" endpoint
     */
    getEventCardOnce() {
        const key: string = 'EVENT_CARD'

        if (!this.keys.includes(key) && this.ssrData?.event_card) {
            this.keys.push(key)

            return parseEvent(this.ssrData.event_card)
        }

        return null
    }

    getH2HOnce() {
        const key: string = 'H2H'

        if (!this.keys.includes(key) && this.ssrData?.h2h) {
            this.keys.push(key)

            return parseEvent(this.ssrData.h2h)
        }

        return null
    }

    /**
     * Get event browser "Meetings" data once from SSR
     *
     * i.e. same as "/eventbrowser?date=" endpoint
     */
    getEventBrowserMeetingsOnce() {
        const key: string = 'EVENT_BROWSER_MEETINGS'

        if (!this.keys.includes(key) && this.ssrData?.event_browser?.meetings) {
            this.keys.push(key)

            return this.ssrData.event_browser.meetings
        }

        return null
    }

    /**
     * Get event browser "Antepost" data once from SSR
     *
     * i.e. same as "/eventbrowser?antepost=1" endpoint
     */
    getEventBrowserAntepostOnce() {
        const key: string = 'EVENT_BROWSER_ANTEPOST'

        if (!this.keys.includes(key) && this.ssrData?.event_browser?.antepost) {
            this.keys.push(key)

            return this.ssrData.event_browser.antepost
        }

        return null
    }

    /**
     * Get event browser "Virtuals" data once from SSR
     *
     * i.e. same as "/eventbrowser?virtuals=1" endpoint
     */
    getEventBrowserVirtualsOnce() {
        const key: string = 'EVENT_BROWSER_VIRTUALS'

        if (!this.keys.includes(key) && this.ssrData?.event_browser?.virtuals) {
            this.keys.push(key)

            return this.ssrData.event_browser.virtuals
        }

        return null
    }

    /**
     * Get calendar data once from SSR
     *
     * i.e. same as "/calendar" endpoint
     */
    getCalendarOnce() {
        const key: string = 'CALENDAR'

        if (!this.keys.includes(key) && this.ssrData?.calendar) {
            this.keys.push(key)

            return parseCalendarEvents(this.ssrData.calendar)
        }

        return null
    }

    /**
     * Get Race Card data once from SSR
     *
     * i.e. same as "/race/ID" endpoint
     */
    getRaceCardOnce() {
        const key: string = 'RACE_CARD'

        if (!this.keys.includes(key) && this.ssrData?.race_card) {
            this.keys.push(key)

            return parseRace(this.ssrData.race_card)
        }

        return null
    }

    /**
     * Get User data from SSR
     */
    getUser() {
        return this.ssrData?.user
    }

    /**
     * Get Betslip data once from SSR
     */
    geBetslipOnce() {
        const key: string = 'BETSLIP'

        if (!this.keys.includes(key) && this.ssrData?.betslip) {
            this.keys.push(key)

            return this.ssrData.betslip
        }

        return null
    }

    getNextRacesOnce(): OBR.Race.UpcomingRacesResponse | null {
        const key: string = 'UPCOMING_RACES'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.upcoming_races) {
            this.keys.push(key)

            return this.ssrData.widgets?.upcoming_races
        }

        return null
    }

    getUpcomingH2HRacesOnce(): OBR.Race.Race[] | null {
        const key: string = 'H2H_RACES'

        if (
            !this.keys.includes(key) &&
            this.ssrData?.widgets?.upcoming_h2h_races?.races
        ) {
            this.keys.push(key)
            return this.ssrData.widgets?.upcoming_h2h_races.races
        }

        return null
    }

    getMarketMoversRacesOnce(): OBR.Race.MarketMoversResponse | null {
        const key: string = 'MARKET_MOVERS'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.market_movers) {
            this.keys.push(key)

            return this.ssrData.widgets?.market_movers
        }

        return null
    }

    getHighlightRacesOnce(): OBR.Generic.HighlightsResponse | null {
        const key: string = 'HIGHLIGHTS'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.highlights) {
            this.keys.push(key)

            return this.ssrData.widgets?.highlights
        }

        return null
    }

    getHorsesAbroadOnce(): OBR.Generic.HorsesAbroadWidgetResponse | null {
        const key: string = 'HORSES_ABROAD'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.horses_abroad) {
            this.keys.push(key)

            return this.ssrData.widgets?.horses_abroad
        }

        return null
    }

    getBestBackedOnce(): OBR.Race.BestBackedResponse | null {
        const key: string = 'BEST_BACKED'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.best_backed) {
            this.keys.push(key)

            return this.ssrData.widgets?.best_backed
        }

        return null
    }

    getTipsOfTheDayOnce(): OBR.Generic.TipsOfTheDayResponse | null {
        const key: string = 'TIPS_OF_THE_DAY'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.tips) {
            this.keys.push(key)

            return this.ssrData.widgets?.tips
        }

        return null
    }

    getTopBetsOnce(): OBR.Generic.HomepageTopBetsWidget | null {
        const key: string = 'TOP_BETS'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.top_bets) {
            this.keys.push(key)

            return this.ssrData.widgets?.top_bets
        }

        return null
    }

    getJackpotsOnce(): OBR.Generic.HomepageJackpotsWidgetResponse | null {
        const key: string = 'JACKPOTS'

        if (!this.keys.includes(key) && this.ssrData?.widgets?.jackpots) {
            this.keys.push(key)

            return this.ssrData.widgets?.jackpots
        }

        return null
    }

    getSpecialOnce(): OBR.Events.Event | null {
        const key: string = 'SPECIAL'

        if (!this.keys.includes(key) && this.ssrData?.special) {
            this.keys.push(key)

            return parseSpecialEvent({
                ...this.ssrData?.special.event,
                races: this.ssrData?.special.races,
            })
        }

        return null
    }

    getSpecialsOnce(): OBR.Events.Event[] | null {
        const key: string = 'SPECIALS'

        if (!this.keys.includes(key) && this.ssrData?.specials) {
            this.keys.push(key)

            return this.ssrData.specials.map((event) =>
                parseSpecialEvent(event)
            )
        }

        return null
    }
}
