import { BetType } from '@obr-core/config/betting'

export function initialState(): OBR.Store.BetslipState {
    return {
        bets: [],
        multiples: [],
        pickBets: {},
        activePickBet: '',
        hasChange: false,
        hasUnmatchedOdds: false,
        potentialBet: null,
        multipleBetType: BetType.WIN,
        dialog: {
            isVisible: false,
        },
        editedExoticBetUid: null,
        pickBetEvetntId: null,
        currencyHasChanged: false,
    }
}
