export function initialState(): OBR.Store.EventState {
    return {
        eventBrowserEvents: [],
        eventsCache: {},
        antepostEvents: [],
        virtualEvents: [],
        pickbetsCache: {},
        selectedPickbetView: undefined,
    }
}
