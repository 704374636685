import { EventService } from '@obr-core/services/api'

/**
 * Events call names
 */
export const EVENT_CARD = 'EVENT_CARD'
export const EVENT_CARD_PICKBET = 'EVENT_CARD_PICKBET'
export const EVENT_CARD_PICKBETS = 'EVENT_CARD_PICKBETS'
export const PICKBETS_BY_RACE = 'PICKBETS_BY_RACE'
export const DATE_EVENTS = 'DATE_EVENTS'
export const ANTEPOST_EVENTS = 'ANTEPOST_EVENTS'
export const VIRTUAL_EVENTS = 'VIRTUAL_EVENTS'

const eventService = EventService.getInstance()

const getEvent = (id: string): Promise<OBR.Events.Event | null> =>
    eventService.getEventById(id)

const dateEvents = (
    args: OBR.Events.EventFilter
): Promise<OBR.Events.Event[]> => eventService.getAllEvents(args)

const antepostEvents = (
    args: OBR.Events.EventFilter
): Promise<OBR.Events.Event[]> => eventService.getAllEvents(args)

const virtualEvents = (
    args: OBR.Events.EventFilter
): Promise<OBR.Events.Event[]> => eventService.getAllEvents(args)

const getEventPickbets = (eventId: string): Promise<OBR.Events.Pickbet[]> =>
    eventService.getPickbets(eventId)

const getPickbetsByRaceId = ({
    raceId,
}: {
    raceId: string
}): Promise<OBR.Events.Pickbet[]> => eventService.getPickbetsByRaceId(raceId)

const getEventPickbet = (
    pickbetId: string
): Promise<OBR.Events.Pickbet | null> => eventService.getPickbet(pickbetId)

export default {
    [EVENT_CARD]: getEvent,
    [DATE_EVENTS]: dateEvents,
    [ANTEPOST_EVENTS]: antepostEvents,
    [VIRTUAL_EVENTS]: virtualEvents,
    [EVENT_CARD_PICKBET]: getEventPickbet,
    [EVENT_CARD_PICKBETS]: getEventPickbets,
    [PICKBETS_BY_RACE]: getPickbetsByRaceId,
}
