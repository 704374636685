import { defineStore } from 'pinia'

import { defaultMedia } from '@obr-core/helpers/streams.helpers'
import { SSRService } from '@obr-core/services/SSRService'
import { extendEvents } from '@obr-ui/helpers/events.helpers'
import { CacheService } from '@obr-core/services/CacheService'
import {
    EVENT_CARD,
    DATE_EVENTS,
    ANTEPOST_EVENTS,
    VIRTUAL_EVENTS,
    EVENT_CARD_PICKBET,
    EVENT_CARD_PICKBETS,
    PICKBETS_BY_RACE,
} from '@obr-core/services/CacheService/resolvers/events'
import { EventService } from '@obr-core/services/api'
import { initialState } from './state'
import { raceStoreService } from '@obr-core/services/store/RaceStoreService'

const eventService = EventService.getInstance()
const cacheService = CacheService.getInstance()

// used for return correct events and mantaince prefetch data (when change multiple time data events)
let eventsLastFilter
export const useEvents = defineStore('obr-store/events', {
    state: initialState,
    actions: {
        async onEventCardLoadEvent(eventId: string) {
            const eventSSR = SSRService.getInstance().getEventCardOnce()

            if (eventSSR) {
                this.onSetEventCardEvent(eventSSR)
                return this.eventsCache[eventId]
            }

            const event = await eventService.getEventById(eventId)
            if (event) {
                this.onSetEventCardEvent(event)
            }

            return this.eventsCache[eventId]
        },
        async onEventCardLoadH2H(eventId: string) {
            const eventSSR = SSRService.getInstance().getH2HOnce()

            if (eventSSR) {
                this.onSetEventCardEvent(eventSSR)
                return this.eventsCache[eventId]
            }

            const event = await eventService.getH2HEventById(eventId)
            if (event) {
                this.onSetEventCardEvent(event)
            }

            return this.eventsCache[eventId]
        },
        onEventCardLoadEventSpecial(eventId: string) {
            const eventSSR = SSRService.getInstance().getSpecialOnce()

            if (eventSSR) {
                this.onSetSpecialsInStore([eventSSR])
                return Promise.resolve(this.eventsCache[eventId])
            }

            const eventPromise: Promise<OBR.Events.Event> = eventService
                .getSpecialEventById(eventId)
                .then((event) => {
                    if (event) {
                        this.onSetSpecialsInStore([event])
                    }

                    return this.eventsCache[eventId]
                })

            return this.eventsCache[eventId]
                ? Promise.resolve(this.eventsCache[eventId])
                : eventPromise
        },
        async onEventCardLoadEventSpecials(): Promise<OBR.Events.Event[]> {
            const eventsSSR = SSRService.getInstance().getSpecialsOnce()

            if (eventsSSR) {
                this.onSetSpecialsInStore(eventsSSR)
                return Promise.resolve(eventsSSR)
            }
            return eventService.getSpecials().then((specials) => {
                if (!specials) return []
                this.onSetSpecialsInStore(specials)
                return specials || []
            })
        },
        onSetSpecialsInStore(specials: OBR.Events.Event[]) {
            specials.forEach((special) => {
                this.eventsCache[special.id] = { ...special }
                special.races.forEach((specialRace) => {
                    raceStoreService.addSpecialRaceToCache(specialRace)
                })
            })
        },
        async onEventBrowserLoadEvents(date: string) {
            eventsLastFilter = date
            const eventsSSR: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserMeetingsOnce() || []
            const events: OBR.Events.Event[] = await cacheService.doCall(
                DATE_EVENTS,
                {
                    args: {
                        date: date,
                    },
                    cacheable: true,
                    preCache: eventsSSR.length ? eventsSSR : undefined,
                }
            )

            if (eventsLastFilter === date) {
                this.onSetEventBrowserEvents(events)
            }
        },
        async onEventBrowserLoadAntepostEvents() {
            const eventsSSR: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserAntepostOnce() || []
            const events: OBR.Events.Event[] = await cacheService.doCall(
                ANTEPOST_EVENTS,
                {
                    args: {
                        antepost: '1',
                    },
                    cacheable: true,
                    preCache: eventsSSR.length ? eventsSSR : undefined,
                }
            )

            this.onSetEventBrowserAntepostEvents(events)
        },
        async onEventBrowserLoadVirtualEvents() {
            const eventsSSR: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserVirtualsOnce() || []
            const events: OBR.Events.Event[] = await cacheService.doCall(
                VIRTUAL_EVENTS,
                {
                    args: {
                        virtual: true,
                    },
                    cacheable: true,
                    preCache: eventsSSR.length ? eventsSSR : undefined,
                }
            )

            this.onSetEventBrowserVirtualEvents(events)
        },
        async onEventBrowserUpdateAntepostBySocket(
            updateEvents: OBR.Common.Object<OBR.Events.Event> = {}
        ) {
            const copyAntepostEvents: OBR.Events.Event[] = extendEvents(
                this.antepostEvents,
                updateEvents
            )
            // update cache call and store manager
            const antepostEvents: OBR.Events.Event[] =
                await cacheService.doCall(ANTEPOST_EVENTS, {
                    args: {
                        antepost: '1',
                    },
                    cacheable: true,
                    preCache: copyAntepostEvents,
                })

            this.onSetEventBrowserAntepostEvents(antepostEvents)
        },
        /**
         * map and update events
         */
        async onEventBrowserUpdateEventsBySocket({
            updateEvents = {},
            date,
        }: {
            updateEvents: OBR.Common.Object<OBR.Events.Event>
            date: string
        }) {
            const copyEvents: OBR.Events.Event[] = extendEvents(
                this.eventBrowserEvents,
                updateEvents
            )

            // update cache call and store manager
            const events: OBR.Events.Event[] = await cacheService.doCall(
                DATE_EVENTS,
                {
                    args: {
                        date: date,
                    },
                    cacheable: true,
                    preCache: copyEvents,
                }
            )

            this.onSetEventBrowserEvents(events)
        },
        /**
         * map and update virtual events
         */
        async onEventBrowserUpdateVirtualEventsBySocket(
            updateEvents: OBR.Common.Object<OBR.Events.Event> = {}
        ) {
            const copyEvents: OBR.Events.Event[] = extendEvents(
                this.virtualEvents,
                updateEvents
            )

            // update cache call and store manager
            const events: OBR.Events.Event[] = await cacheService.doCall(
                VIRTUAL_EVENTS,
                {
                    args: {
                        virtual: true,
                    },
                    cacheable: true,
                    preCache: copyEvents,
                }
            )

            this.onSetEventBrowserVirtualEvents(events)
        },
        /**
         * update races inside event cached and update isomorphic
         */
        onEventUpdateRacesBySocket({
            eventID,
            races,
        }: {
            eventID: string
            races: OBR.Events.SocketEventCardRaceNormalized[]
        }) {
            if (this.eventsCache[eventID] && this.eventsCache[eventID].races) {
                this.eventsCache[eventID].races = this.eventsCache[
                    eventID
                ].races.map((cacheRace) => {
                    const raceUpdate = races.find(
                        (race) => race.id === cacheRace.id
                    )
                    if (raceUpdate) {
                        if (raceUpdate.runners) {
                            raceUpdate.runners = cacheRace.runners.map(
                                (cacheRunner) => {
                                    const runnerUpdate =
                                        raceUpdate.runners?.find(
                                            (updateRunner) =>
                                                updateRunner.id ===
                                                cacheRunner.id
                                        )

                                    if (runnerUpdate) {
                                        return Object.assign(
                                            cacheRunner,
                                            runnerUpdate
                                        )
                                    }
                                    return cacheRunner
                                }
                            )
                        }
                        return Object.assign(cacheRace, raceUpdate)
                    }
                    return cacheRace
                })
            }
        },
        /**
         * load pickbets by eventId and set cache
         */
        async loadPickbetsByEventIdAndSetCache(
            eventId: string
        ): Promise<OBR.Events.Pickbet[]> {
            const pickbets: OBR.Events.Pickbet[] = await cacheService.doCall(
                EVENT_CARD_PICKBETS,
                {
                    args: eventId,
                    cacheable: true,
                }
            )

            const hasCrossEvents = pickbets.some((pick) => !pick.single_event)

            if (hasCrossEvents) {
                const picks = pickbets.filter((pick) => !pick.single_event)

                for await (const pick of picks) {
                    await this.loadCrossEventsAndSetCache(pick, eventId)
                }
            }

            return pickbets.map((pickbet) => {
                this.setPickbetCache(pickbet)

                return this.pickbetsCache[pickbet.id]
            })
        },
        /**
         * Load cross events and set cache
         */
        async loadCrossEventsAndSetCache(
            pickbet: OBR.Events.Pickbet,
            eventId: string
        ): Promise<void> {
            const crossEventsIds = new Set<string>()

            pickbet.races.forEach((race) => {
                if (String(race.id_event) !== eventId)
                    crossEventsIds.add(race.id_event)
            })

            const crossEvents = await Promise.all(
                [...crossEventsIds].map((id) =>
                    cacheService.doCall(EVENT_CARD, {
                        args: id,
                        cacheable: true,
                    })
                )
            )

            crossEvents.forEach((event) => {
                this.onSetEventCardEvent(event)
            })
        },
        /**
         * load pickbets by raceId
         */
        async loadPickbetsByRaceId({
            raceId,
            bettypes,
        }: {
            raceId: string
            bettypes: string
        }): Promise<OBR.Events.Pickbet[]> {
            const pickbets: OBR.Events.Pickbet[] = await cacheService.doCall(
                PICKBETS_BY_RACE,
                {
                    args: { raceId, bettypes },
                    cacheable: true,
                }
            )

            return pickbets.map((pickbet) => {
                this.setPickbetCache(pickbet)

                return this.pickbetsCache[pickbet.id]
            })
        },
        /**
         * Load pickbets by pickbetId and save them to cache
         */
        async onEventCardLoadPickbetById(
            pickbetId: string,
            eventID?: string
        ): Promise<OBR.Events.Pickbet | null> {
            const pickbet: OBR.Events.Pickbet | null =
                await cacheService.doCall(EVENT_CARD_PICKBET, {
                    args: pickbetId,
                    cacheable: true,
                })

            if (pickbet) {
                this.setPickbetCache(pickbet)

                if (!pickbet.single_event) {
                    await this.loadCrossEventsAndSetCache(
                        pickbet,
                        eventID as string
                    )
                }

                return this.pickbetsCache[pickbet.id] as OBR.Events.Pickbet
            }

            return null
        },
        onSetEventCardEvent(event: OBR.Events.Event) {
            this.onSetEventBrowserEventsCache({ event })

            event.races.forEach((race) => {
                raceStoreService.addRaceToStore(
                    race,
                    !!raceStoreService.raceByCache(race.id)
                )
            })
        },
        onSetEventCardEventWithIsomorphic(event: OBR.Events.Event) {
            cacheService.doCall(EVENT_CARD, {
                args: event.id,
                cacheable: true,
                preCache: event,
            })
            this.onSetEventCardEvent(event)
        },
        onSetEventBrowserEvents(events: OBR.Events.Event[]) {
            this.eventBrowserEvents = events
            this.onSetEventBrowserEventsCache({ events })
        },
        onSetEventBrowserEventsCache({
            event,
            events,
        }: {
            event?: OBR.Events.Event
            events?: OBR.Events.Event[]
        }) {
            if (event) {
                this.eventsCache[event.id] = {
                    ...this.eventsCache[event.id],
                    ...event,
                }
            } else {
                events?.forEach((_event) => {
                    // if (_event.is_special) return
                    const media =
                        _event.has_media &&
                        _event.media_type &&
                        _event.stream_type
                            ? {
                                  ...defaultMedia(),
                                  media_type: _event.media_type,
                                  stream_type: _event.stream_type,
                              }
                            : undefined
                    if (!this.eventsCache[_event.id]) {
                        this.eventsCache[_event.id] = { ..._event, media }
                    }
                })
            }
            this.eventsCache = { ...this.eventsCache }
        },
        onSetEventBrowserAntepostEvents(payload: OBR.Events.Event[]) {
            this.antepostEvents = payload
        },
        onSetEventBrowserVirtualEvents(payload: OBR.Events.Event[]) {
            this.virtualEvents = payload
        },
        onSetPickbetsView(view?: string) {
            this.selectedPickbetView = view
        },

        /**
         * Set pickbet cache
         * @param {OBR.Events.Pickbet} pickbet
         */
        setPickbetCache(pickbet: OBR.Events.Pickbet) {
            this.pickbetsCache[pickbet.id] = pickbet
        },
    },
})
