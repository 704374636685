/**
 * Media Type
 */
export const enum StreamType {
    ARC = 'ARC',
    LIV = 'LIV',
    MP4 = 'MP4',
    MSC = 'MSC',
}

/**
 * Media type (Video or Audio)
 */
export const enum MediaType {
    VID = 'VID',
    AUD = 'AUD',
}

/**
 * Media Provider
 */
export const enum Provider {
    ATR = 'ATR',
    EGT = 'EGT',
    HEX = 'HEX',
    PFG = 'PFG',
    SISTV = 'SISTV',
    SKY = 'SKY',
    TV1 = 'TV1',
    YOUTUBE = 'YOUTUBE',
}

/**
 * Media Error enum
 */
export const enum MediaError {
    INVALID_RESOURCE = 'INVALID_RESOURCE',
    COUNTRY_BLOCKED = 'COUNTRY_BLOCKED',
    NO_TURNOVER_UK = 'NO_TURNOVER_UK',
    NO_TURNOVER = 'NO_TURNOVER',
    TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
    NO_ARCHIVE = 'NO_ARCHIVE',
    EVENT_NOT_STARTED = 'EVENT_NOT_STARTED',
    EVENT_OVER = 'EVENT_OVER',
}
