import { raceStoreService } from '@obr-core/services/store'
import {
    getDisplayValueForJackpotLabel,
    getDisplayValueForPurse,
} from '@obr-core/helpers/display-value.helpers'
import { i18n } from '@obr-core/i18n/i18n'

export function formatPickBetSelection(
    pickBet: OBR.Events.Pickbet
): OBR.Betting.PickBetSelection {
    const pickBetSelection: OBR.Betting.PickBetSelection = {}

    pickBet.races.forEach((race, i) => {
        //add race info to pickBetSelection
        const raceInfo = raceStoreService.raceByCache(race.id_race)

        if (!raceInfo) {
            return
        }

        pickBetSelection[race.id_race] = {
            leg: i + 1,
            country: raceInfo.country,
            event_title: raceInfo.event_title,
            post_time: raceInfo.post_time,
            race_id: raceInfo.id,
            race_number: raceInfo.number,
            runners: {},
        }
    })

    return pickBetSelection
}

export function formatPickBetLeg({
    id_pickbet,
    runner,
    race,
}: OBR.UI.Components.PickBets.UpdateBetslipPickBetPayload): OBR.UI.Components.PickBets.UpdateBetslipPickBetLegPayload {
    return {
        idPickBet: id_pickbet,
        idLeg: race.id,
        runner: {
            id_runner: runner.id,
            name: runner.name,
            program_number: runner.program_number,
            silk_path: runner.silk_path,
            odds_fxw: runner.odds_fxw,
            excluded: false,
            scratched: false,
        },
    }
}

export function formatPickedBetsTags(
    jackpot: OBR.Race.RaceJackpot | undefined,
    pool: {
        amount: number
        currency: string
        carryover: number
    }
) {
    const tags = []
    if (jackpot) {
        tags.push({
            text: getDisplayValueForJackpotLabel(jackpot, true, false),
        })
    } else if (pool && (pool.amount || pool.carryover)) {
        tags.push({
            pool: pool.amount
                ? getDisplayValueForPurse(pool.amount, pool.currency)
                : undefined,
            carryover: pool.carryover
                ? getDisplayValueForPurse(pool.carryover, pool.currency)
                : undefined,
            text: i18n.global.t('racing.label_current_pool', {
                value: getDisplayValueForPurse(
                    pool.amount + pool.carryover,
                    pool.currency
                ),
            }),
        })
    }

    return tags
}
