/**
 * Accept odds changes all values
 */
export enum AcceptBetsChanges {
    ALL = 'all',
    BIGGER = 'bigger',
    NO = 'no',
}

export const LIMITED_COUNTRIES_IN_COUNTRY_ORDER_LIST = [
    'AE',
    'AR',
    'AT',
    'AU',
    'BE',
    'BH',
    'BR',
    'CA',
    'CH',
    'CL',
    'CN',
    'CZ',
    'DE',
    'DK',
    'DO',
    'ES',
    'FI',
    'FR',
    'GB',
    'HK',
    'HU',
    'IE',
    'IN',
    'IT',
    'JP',
    'KR',
    'MA',
    'MO',
    'MT',
    'MU',
    'MX',
    'NO',
    'NZ',
    'PA',
    'PE',
    'QA',
    'SA',
    'SE',
    'SG',
    'TR',
    'US',
    'UY',
    'ZA',
    'ZW',
]

/**
 * List of time zones
 */
export const PRESET_TIME_ZONE_LIST = [
    {
        id: 0,
        name: 'Etc/GMT+12',
        title: '(UTC-12:00) GMT+12',
    },
    {
        id: 1,
        name: 'Pacific/Apia',
        title: '(UTC-11:00) Apia',
    },
    {
        id: 2,
        name: 'Pacific/Pago_Pago',
        title: '(UTC-11:00) Midway Island, Samoa',
    },
    {
        id: 3,
        name: 'Pacific/Marquesas',
        title: '(UTC-10:30) Marquesas Islands',
    },
    {
        id: 4,
        name: 'America/Adak',
        title: '(UTC-10:00) Hawaii-Aleutian',
    },
    {
        id: 5,
        name: 'Pacific/Honolulu',
        title: '(UTC-10:00) Hawaii',
    },
    {
        id: 6,
        name: 'Pacific/Gambier',
        title: '(UTC-09:30) Gambier Islands',
    },
    {
        id: 7,
        name: 'America/Anchorage',
        title: '(UTC-09:00) Alaska',
    },
    {
        id: 8,
        name: 'America/Los_Angeles',
        title: '(UTC-08:00) Pacific Time (US & Canada)',
    },
    {
        id: 9,
        name: 'Pacific/Pitcairn',
        title: '(UTC-08:00) Pitcairn Islands',
    },
    {
        id: 10,
        name: 'America/Santa_Isabel',
        title: '(UTC-08:00) Santa Isabel',
    },
    {
        id: 11,
        name: 'America/Phoenix',
        title: '(UTC-07:00) Arizona',
    },
    {
        id: 12,
        name: 'America/Denver',
        title: '(UTC-07:00) Mountain Time (US & Canada)',
    },
    {
        id: 13,
        name: 'America/Mazatlan',
        title: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    },
    {
        id: 14,
        name: 'America/Guatemala',
        title: '(UTC-06:00) Saskatchewan, Central America',
    },
    {
        id: 15,
        name: 'America/Chicago',
        title: '(UTC-06:00) Central Time (US & Canada)',
    },
    {
        id: 16,
        name: 'America/Mexico_City',
        title: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    },
    {
        id: 17,
        name: 'America/Caracas',
        title: '(UTC-05:30) Caracas',
    },
    {
        id: 18,
        name: 'America/Bogota',
        title: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    },
    {
        id: 19,
        name: 'Pacific/Easter',
        title: '(UTC-05:00) Easter Island',
    },
    {
        id: 20,
        name: 'America/Havana',
        title: '(UTC-05:00) Cuba',
    },
    {
        id: 21,
        name: 'America/New_York',
        title: '(UTC-05:00) Eastern Time (US & Canada)',
    },
    {
        id: 22,
        name: 'America/St_Johns',
        title: '(UTC-04:30) Newfoundland',
    },
    {
        id: 23,
        name: 'America/Goose_Bay',
        title: '(UTC-04:00) Atlantic Time (Goose Bay)',
    },
    {
        id: 24,
        name: 'America/Halifax',
        title: '(UTC-04:00) Atlantic Time (Canada)',
    },
    {
        id: 25,
        name: 'America/Santo_Domingo',
        title: '(UTC-04:00) Santo Domingo',
    },
    {
        id: 26,
        name: 'Atlantic/Stanley',
        title: '(UTC-03:00) Faukland Islands',
    },
    {
        id: 27,
        name: 'America/Asuncion',
        title: '(UTC-03:00) Asuncion',
    },
    {
        id: 28,
        name: 'America/Santiago',
        title: '(UTC-03:00) Santiago',
    },
    {
        id: 29,
        name: 'America/Campo_Grande',
        title: '(UTC-03:00) Brazil',
    },
    {
        id: 30,
        name: 'America/Miquelon',
        title: '(UTC-03:00) Miquelon, St. Pierre',
    },
    {
        id: 31,
        name: 'America/Godthab',
        title: '(UTC-03:00) Greenland',
    },
    {
        id: 32,
        name: 'America/Argentina/Buenos_Aires',
        title: '(UTC-03:00) Buenos Aires',
    },
    {
        id: 33,
        name: 'America/Montevideo',
        title: '(UTC-02:00) Montevideo',
    },
    {
        id: 34,
        name: 'America/Noronha',
        title: '(UTC-02:00) Mid-Atlantic',
    },
    {
        id: 35,
        name: 'America/Sao_Paulo',
        title: '(UTC-02:00) Brasilia',
    },
    {
        id: 36,
        name: 'Atlantic/Azores',
        title: '(UTC-01:00) Azores',
    },
    {
        id: 37,
        name: 'Atlantic/Cape_Verde',
        title: '(UTC-01:00) Cape Verde Is.',
    },
    {
        id: 38,
        name: 'Europe/London',
        title: '(UTC) London, Lisbon, Dublin, Monrovia, Reykjavik',
    },
    {
        id: 39,
        name: 'Africa/Casablanca',
        title: '(UTC) Casablanca',
    },
    {
        id: 40,
        name: 'Europe/Berlin',
        title: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    {
        id: 41,
        name: 'Africa/Lagos',
        title: '(UTC+01:00) West Central Africa',
    },
    {
        id: 42,
        name: 'Africa/Windhoek',
        title: '(UTC+02:00) Windhoek',
    },
    {
        id: 43,
        name: 'Asia/Gaza',
        title: '(UTC+02:00) Gaza',
    },
    {
        id: 44,
        name: 'Asia/Beirut',
        title: '(UTC+02:00) Beirut',
    },
    {
        id: 46,
        name: 'Africa/Johannesburg',
        title: '(UTC+02:00) Harare, Pretoria',
    },
    {
        id: 47,
        name: 'Europe/Helsinki',
        title: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    },
    {
        id: 48,
        name: 'Asia/Jerusalem',
        title: '(UTC+02:00) Jerusalem',
    },
    {
        id: 49,
        name: 'Africa/Cairo',
        title: '(UTC+02:00) Cairo',
    },
    {
        id: 50,
        name: 'Asia/Damascus',
        title: '(UTC+02:00) Syria',
    },
    {
        id: 51,
        name: 'Asia/Amman',
        title: '(UTC+03:00) Amman',
    },
    {
        id: 53,
        name: 'Asia/Baghdad',
        title: '(UTC+03:00) Nairobi, Baghdad',
    },
    {
        id: 54,
        name: 'Asia/Yerevan',
        title: '(UTC+04:00) Yerevan',
    },
    {
        id: 55,
        name: 'Asia/Baku',
        title: '(UTC+04:00) Baku',
    },
    {
        id: 56,
        name: 'Asia/Dubai',
        title: '(UTC+04:00) Abu Dhabi, Muscat',
    },
    {
        id: 57,
        name: 'Asia/Tehran',
        title: '(UTC+04:30) Tehran',
    },
    {
        id: 58,
        name: 'Asia/Yekaterinburg',
        title: '(UTC+05:00) Ekaterinburg',
    },
    {
        id: 59,
        name: 'Asia/Karachi',
        title: '(UTC+05:00) Tashkent',
    },
    {
        id: 60,
        name: 'Asia/Kabul',
        title: '(UTC+05:30) Kabul',
    },
    {
        id: 61,
        name: 'Asia/Omsk',
        title: '(UTC+06:00) Novosibirsk',
    },
    {
        id: 62,
        name: 'Asia/Dhaka',
        title: '(UTC+06:00) Astana, Dhaka',
    },
    {
        id: 63,
        name: 'Asia/Kolkata',
        title: '(UTC+06:30) Chennai, Kolkata, Mumbai, New Delhi',
    },
    {
        id: 64,
        name: 'Asia/Kathmandu',
        title: '(UTC+06:45) Kathmandu',
    },
    {
        id: 65,
        name: 'Asia/Krasnoyarsk',
        title: '(UTC+07:00) Krasnoyarsk',
    },
    {
        id: 66,
        name: 'Asia/Jakarta',
        title: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    },
    {
        id: 67,
        name: 'Asia/Rangoon',
        title: '(UTC+07:30) Yangon (Rangoon)',
    },
    {
        id: 68,
        name: 'Asia/Irkutsk',
        title: '(UTC+08:00) Irkutsk, Ulaan Bataar',
    },
    {
        id: 69,
        name: 'Asia/Shanghai',
        title: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi, Perth',
    },
    {
        id: 70,
        name: 'Asia/Yakutsk',
        title: '(UTC+09:00) Yakutsk',
    },
    {
        id: 71,
        name: 'Asia/Tokyo',
        title: '(UTC+09:00) Osaka, Sapporo, Tokyo, Seoul',
    },
    {
        id: 72,
        name: 'Australia/Eucla',
        title: '(UTC+09:45) Eucla',
    },
    {
        id: 73,
        name: 'Asia/Vladivostok',
        title: '(UTC+10:00) Vladivostok',
    },
    {
        id: 74,
        name: 'Australia/Brisbane',
        title: '(UTC+10:00) Brisbane',
    },
    {
        id: 75,
        name: 'Australia/Darwin',
        title: '(UTC+10:30) Darwin',
    },
    {
        id: 76,
        name: 'Australia/Lord_Howe',
        title: '(UTC+11:00) Lord Howe Island',
    },
    {
        id: 77,
        name: 'Australia/Sydney',
        title: '(UTC+11:00) Sydney, Hobart',
    },
    {
        id: 78,
        name: 'Asia/Kamchatka',
        title: '(UTC+11:00) Kamchatka',
    },
    {
        id: 79,
        name: 'Pacific/Noumea',
        title: '(UTC+11:00) Solomon Is., New Caledonia',
    },
    {
        id: 80,
        name: 'Australia/Adelaide',
        title: '(UTC+11:30) Adelaide',
    },
    {
        id: 81,
        name: 'Pacific/Fiji',
        title: '(UTC+12:00) Anadyr, Kamchatka',
    },
    {
        id: 82,
        name: 'Pacific/Fiji',
        title: '(UTC+12:00) Anadyr, Kamchatka',
    },
    {
        id: 83,
        name: 'Pacific/Tarawa',
        title: '(UTC+12:00) Tarawa',
    },
    {
        id: 84,
        name: 'Pacific/Norfolk',
        title: '(UTC+12:30) Norfolk Island',
    },
    {
        id: 85,
        name: 'Pacific/Auckland',
        title: '(UTC+13:00) Auckland, Wellington',
    },
    {
        id: 86,
        name: 'Pacific/Tongatapu',
        title: '(UTC+13:00) Nuku Alofa',
    },
    {
        id: 87,
        name: 'Pacific/Kiritimati',
        title: '(UTC+14:00) Kiritimati',
    },
    {
        id: 88,
        name: 'Pacific/Chatham',
        title: '(UTC+14:45) Chatham Islands',
    },
]
