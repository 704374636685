import {
    EVENTS_DEFAULT_COUNTRY_ORDER,
    EVENTS_RELATED_COUNTRY_ORDER,
} from '@obr-core/config/events'
import {
    Jurisdiction,
    SETTINGS_DEFAULT_ACTIVE_RACE_TYPES,
    SETTINGS_RACE_TYPE_FILTERS,
} from '@obr-core/config/settings'
import { STORAGE_KEY_ACTIVE_RACE_TYPES } from '@obr-core/config/storage'
import { getItem } from '@obr-core/lib/storage.manager'
import { RaceType } from '@obr-core/config/race'

/**
 * Group events by event country
 * @param events
 */
export function groupEventsByCountry<T extends { country: string }>(
    events: T[]
): OBR.Events.GroupByCountry<T>[] {
    const groups: OBR.Common.Object<T[]> = {}

    for (let i = 0; i < events.length; i += 1) {
        const event = events[i]

        if (!groups[event.country]) {
            groups[event.country] = []
        }

        groups[event.country].push(event)
    }

    return Object.keys(groups).map((country) => ({
        country,
        events: groups[country],
    }))
}

/**
 * Filter events with specific raceTypes
 * @param events
 * @param raceTypes
 */
export function filterEventsByRaceType(
    events: OBR.Events.Event[],
    raceTypes: RaceType[]
) {
    return events.filter((event) => {
        if (event.race_types) {
            for (let k = 0; k < event.race_types.length; k += 1) {
                if (
                    // eslint-disable-next-line no-prototype-builtins
                    event.race_types.hasOwnProperty(k) &&
                    raceTypes.includes(
                        event.race_types[k] as unknown as RaceType
                    )
                ) {
                    return true
                }
            }

            return false
        }
    })
}

/**
 *
 * @param userCountry
 */
export function getRelatedCountries(userCountry: string): string[] {
    return EVENTS_RELATED_COUNTRY_ORDER[userCountry] || []
}

/**
 *
 * @param userSettings
 * @param userCountry
 */
export function getPreferredCountryOrder(
    userSettings: string[],
    userCountry: string
): string[] {
    const userPreference = userSettings.length ? userSettings : [userCountry]
    const uniqueCountries = new Set([
        ...userPreference,
        ...getRelatedCountries(userCountry),
        ...EVENTS_DEFAULT_COUNTRY_ORDER,
    ])
    return Array.from(uniqueCountries)
}

/**
 *
 * @param events
 * @param countryOrder
 */
export function sortByCountry<T>(
    eventGroups: OBR.Events.GroupByCountry<any>[],
    countryOrder: string[]
): OBR.Events.GroupByCountry<T>[] {
    const result: OBR.Events.GroupByCountry<any>[] = []

    // 1. Sort by country order
    countryOrder.forEach((key) => {
        eventGroups.forEach((item) => {
            if (item.country === key) {
                result.push(item)
            }
        })
    })

    // 2. Sort by country name alphabetically, if not in country order
    eventGroups
        .filter((item) => !countryOrder.includes(item.country))
        .sort((a, b) => a.country.localeCompare(b.country))
        .forEach((item) => result.push(item))

    return result
}

/**
 * Sort events list
 * @param events
 * @param key
 * @param order
 */
export function sortEventsList(
    events: OBR.Events.Event[],
    key: keyof OBR.Events.Event,
    order: OBR.UI.Helpers.Order = 'asc'
): OBR.Events.Event[] {
    return events
        .slice()
        .sort((a: any, b: any) =>
            order === 'asc' ? a[key] - b[key] : b[key] - a[key]
        )
}

/**
 * Get updated list of events when receiving WS updates
 * @param events
 * @param updatedEvents
 * @param antePost
 */
export function updateEvents(
    events: OBR.Events.Event[],
    updatedEvents: {
        [id_event: string]: OBR.Events.Event
    },
    antePost: boolean = false
): OBR.Events.Event[] {
    //updated events
    const merged = events.reduce((acc, event) => {
        // eslint-disable-next-line no-prototype-builtins
        if (updatedEvents.hasOwnProperty(event.id)) {
            const updatedEvent = updatedEvents[event.id]
            if (!updatedEvent.is_offline) {
                updatedEvent.id = event.id
                acc.push(updatedEvent)
            }
            delete updatedEvents[event.id]
        } else {
            acc.push(event)
        }
        return acc
    }, [] as OBR.Events.Event[])

    //new events
    for (const updatedEventId in updatedEvents) {
        const updatedEvent = updatedEvents[updatedEventId]
        if (
            (!antePost && updatedEvent.is_ante_post) ||
            (antePost &&
                (!updatedEvent.is_ante_post || updatedEvent.is_special))
        ) {
            //ignore
            //TODO: remove once this is fixed from PushServer
            continue
        }
        if (!updatedEvent.is_offline) {
            updatedEvent.id = updatedEventId
            merged.push(updatedEvent)
        }
    }

    return merged
}

/**
 * Get available race types for filtering based on IP country
 * @param ipCountry
 */
export function getRaceTypeFilters(
    ipCountry: OBR.Settings.Country,
    areDogsHidden: boolean
): RaceType[] {
    return filterRaceTypesByCountry(
        SETTINGS_RACE_TYPE_FILTERS,
        ipCountry,
        areDogsHidden
    )
}

/**
 * Get active race types from local storage based on IP country
 * Defaults to settings active race types
 * @param ipCountry
 */
export function getActiveRaceTypes(
    ipCountry: OBR.Settings.Country,
    areDogsHidden: boolean
): RaceType[] {
    const raceTypes =
        getItem(STORAGE_KEY_ACTIVE_RACE_TYPES) ||
        SETTINGS_DEFAULT_ACTIVE_RACE_TYPES
    return filterRaceTypesByCountry(raceTypes, ipCountry, areDogsHidden)
}

/**
 * Get the default race type filters based on IP country
 * @param ipCountry
 */
export function getDefaultActiveRaceTypes(
    ipCountry: OBR.Settings.Country,
    areDogsHidden: boolean
): OBR.Race.RaceType[] {
    return filterRaceTypesByCountry(
        SETTINGS_RACE_TYPE_FILTERS,
        ipCountry,
        areDogsHidden
    )
}

/**
 * Filter out race types based on IP country
 * @param raceTypes
 * @param ipCountry
 */
function filterRaceTypesByCountry(
    raceTypes: OBR.Race.RaceType[],
    ipCountry: OBR.Settings.Country,
    areDogsHidden: boolean
): OBR.Race.RaceType[] {
    return raceTypes.filter((raceType: RaceType) => {
        if (
            raceType === RaceType.GREYHOUND &&
            (ipCountry === Jurisdiction.GERMANY || areDogsHidden)
        ) {
            return false
        }
        return true
    })
}

export function extendEvents(
    oldEvents: OBR.Events.Event[],
    updateEvents: OBR.Common.Object<OBR.Events.Event>
): OBR.Events.Event[] {
    const copyEvents: OBR.Events.Event[] = JSON.parse(JSON.stringify(oldEvents))
    for (const eventID in updateEvents) {
        const findEvent = copyEvents.find(
            (event: OBR.Events.Event) => event.id === eventID
        )
        if (findEvent) {
            // Ignore has pick bets update from node due to logic not matching between PHP and Pushserver
            if (!findEvent.has_pick_bets) {
                updateEvents[eventID].has_pick_bets = findEvent.has_pick_bets
            }
            extendEvent(findEvent, updateEvents[eventID])
        } else if (!updateEvents[eventID].is_offline) {
            copyEvents.push({ ...updateEvents[eventID], id: eventID })
        }
    }

    return copyEvents
}

export function extendEvent(
    oldEvent: OBR.Events.Event,
    newEvent: OBR.Events.Event
) {
    return Object.assign(oldEvent, newEvent)
}

export function normalizeRaceByEventSocket(
    racesOrigin: OBR.Events.SocketEventCardPayloadRace[]
): Array<OBR.Events.SocketEventCardRaceNormalized> {
    return racesOrigin.map((race) => {
        const normalizeRace: OBR.Events.SocketEventCardRaceNormalized = {
            id: `${race.idRace}`,
            num_runners: race.numRunners || race.runners?.length || 0,
            post_time: race.postTime,
            status: race.raceStatus,
            scratches: race.scratches || [],
        }

        race.runners?.length &&
            (normalizeRace.runners = race.runners.map((runner) => ({
                id: `${runner.idRunner}`,
                scratched: runner.scratched,
                odds_fxw: runner.winOdds,
            })))

        race.favourites?.length &&
            (normalizeRace.favourites = race.favourites.map((fav) => ({
                program_number: fav.programNumber,
                horse_name: fav.horseName,
                win_odds: fav.winOdds,
            })))

        race.results?.length &&
            (normalizeRace.results = race.results.map((res) => ({
                final_position: res.finalPosition,
                program_number: res.programNumber,
                horse_name: res.horseName,
                win_odds: res.winOdds,
            })))
        return normalizeRace
    })
}

export function sortEventsByFirstStartAndOverStatus(
    events: OBR.Events.Event[]
): OBR.Events.Event[] {
    return events.sort((a, b) => {
        if (a.is_over && !b.is_over) {
            return 1
        } else if (!a.is_over && b.is_over) {
            return -1
        } else {
            return a.first_start - b.first_start
        }
    })
}
