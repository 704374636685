import cache from './cache'
import resolvers from './resolvers'

const mapResolvers: {
    [key: string]: (args: any) => Promise<any>
} = {
    ...resolvers,
}

const fetching: {
    [key: string]: Promise<any>
} = {}

/**
 * Isomorphic Call Service
 * Singleton
 * @description use variable to switch REST API or GRAPHQL
 */
export interface IdoCallParams {
    args?: any
    cacheable?: boolean
    preCache?: any
}
export class CacheService {
    private static instance: CacheService

    /**
     * Return class instance
     */
    public static getInstance(): CacheService {
        if (CacheService.instance === undefined) {
            CacheService.instance = new CacheService()
        }
        return CacheService.instance
    }

    public async doCall(
        methodName: string,
        { args = null, cacheable = false, preCache = undefined }: IdoCallParams
    ) {
        const hash = this.generateHash({
            methodName,
            args,
        })
        if (preCache !== undefined) {
            cache.setItem(hash, preCache)
        }
        return await this.doCachableCall({
            hash,
            cacheable,
            // remove all graph call
            call: mapResolvers[methodName],
            args,
        })
    }

    public generateHash(opt: any): string {
        return JSON.stringify(opt).replace(/[' ']/g, '')
    }

    /**
     *  this funciton execute rest/graph call or try to get from cache if is requested
     */
    public doCachableCall({
        hash,
        cacheable,
        call,
        args,
    }: {
        hash: string
        cacheable: boolean
        call: (args: any) => Promise<any>
        args: (args: any) => Promise<any>
    }): Promise<any> {
        // if same call is in fetching
        if (fetching[hash] !== undefined) {
            return fetching[hash]
        }
        // check if I have response in cache
        if (cacheable && cache.getItem(hash)) {
            return Promise.resolve(cache.getItem(hash))
        }
        // do call
        fetching[hash] = call(args)
            .then((data: any) => {
                // save in cache for future call
                cache.setItem(hash, data)
                return data
            })
            .finally(() => {
                delete fetching[hash]
            })
        return fetching[hash]
    }

    /**
     * Clean specific cache
     * methodName and args are important, because need to generate same hash like first call
     * @param methodName
     * @param {{ args }} args
     */
    public cleanCache(methodName: string, { args = null }: IdoCallParams) {
        const hash = this.generateHash({
            methodName,
            args,
        })
        cache.setItem(hash, undefined)
    }

    /**
     * reset cache
     */
    public cleanAllCache() {
        cache.reset()
    }
}
