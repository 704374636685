/**
 * Compare if year, month and day of two dates are the same
 */
export function compareDates(date1: Date, date2: Date): boolean {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    )
}

/**
 * Check is post time in past
 *
 * @param timestamp in seconds like: 1671485401
 */
export function isTimestampInPast(timestamp: number) {
    const diff = new Date().getTime() - timestamp * 1000

    return diff < 0
}
